import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form } from 'react-bootstrap';
import { UserSignup } from "../redux/actions";
import { AesEncrypt } from 'aes';
import { Success_Message, Error_Message } from "../shared";

export const RegistrationForm = () => {
    let history = useHistory();
    let res = {};
    const [passwordMatch, setPasswordMatch] = useState("");

    const [user_data, setUser] = useState({
        title: '',
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        password: '',
        confirm_password: '',
        membership_type: localStorage.getItem("membership_type")
    });

    const titles = [
        // { id: '1', name: 'Brother' },
        // { id: '2', name: 'Canon' },
        // { id: '3', name: 'Captain' },
        // { id: '4', name: 'Colonel' },
        // { id: '5', name: 'Dame' },
        // { id: '6', name: 'Deacon' },
        { id: '7', name: 'Dr' },
        // { id: '8', name: 'Duchess' },
        // { id: '9', name: 'Duke' },
        // { id: '10', name: 'Father' },
        // { id: '5', name: 'Flight Sergeant' },
        // { id: '11', name: 'Flying Officer' },
        // { id: '12', name: 'Group Captain' },
        // { id: '13', name: 'Lady' },
        // { id: '14', name: 'Lance Corporal' },
        // { id: '15', name: 'Lieutenant' },
        // { id: '16', name: 'Lord' },
        // { id: '17', name: 'Major' },
        { id: '18', name: 'Miss' },
        { id: '19', name: 'Mr' },
        { id: '20', name: 'Mrs' },
        // { id: '25', name: 'Ms' },
        // { id: '26', name: 'Prebend' },
        { id: '27', name: 'Professor' },
        // { id: '28', name: 'Rabbi' },
        // { id: '29', name: 'Reverend' },
        // { id: '30', name: 'Rt Hon' },
        // { id: '31', name: 'Sir' },
        // { id: '32', name: 'Sister' },
        // { id: '33', name: 'Squadron Leader' },
        // { id: '34', name: 'The Hon.' },
        // { id: '35', name: 'Viscount' },
        // { id: '36', name: 'Wing Commander' },
    ]

    const [submitted, setSubmitted] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      if(localStorage.getItem("membership_type") === '') {
        history.push('/')
      }
    }, [])
    

    function handleChange(e) {
        const { name, value } = e.target;
        if([name] != "confirm_password") {
            
            setUser(user_data => ({ ...user_data, [name]: value }));
        }else {
            setUser(user_data => ({ ...user_data, [name]: value }));
            if(value == user_data.password) {
                setSubmitted(true);
                setPasswordMatch("")
            }else {
                setSubmitted(false);
                setPasswordMatch("Password does not match")
            }
        }
        
    }

    async function handleSubmit(e) {
        e.preventDefault();
        
        setSubmitted(true);
        if ( user_data.title && user_data.first_name && user_data.last_name && user_data.username && user_data.email && user_data.password && user_data.confirm_password ) {
            const user_data1 = AesEncrypt(user_data, 'where do you go when you by yourself' );
            document.getElementById("loader").style.display = "block";
            res = await dispatch(UserSignup(user_data1));
            document.getElementById("loader").style.display = "none";

            if(res.status === "success"){ 
                localStorage.setItem("username", user_data.username)
                localStorage.setItem("user_data", JSON.stringify(res.data))
                localStorage.setItem("isAuth", true);
                
                await Success_Message("Successful. Please continue.") 
                history.push('/complete-registration')
                // window.location.href = '/upload-profile-picture'; 
            }else{
                Error_Message(res.data)
            }
        }
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <div className="input-group mb-3 row">
                    <label htmlFor="title" className="col-sm-4 col-form-label form-label">Title:</label>
                    <div className="col-sm-5">
                        <select 
                            className={'reg-input' + (submitted && !user_data.title ? ' is-invalid' : '')}
                            value={user_data.title} 
                            name="title" 
                            onChange={handleChange} 
                        >
                            <option value="select">Choose...</option>
                            {titles && titles.map((title, index) => (
                                <option 
                                    key={index}
                                    value={title.name}
                                >{title.name}
                                </option>
                            ))}
                        </select>
                        {submitted && !user_data.title &&
                            <div className="invalid-feedback">Title is required</div>
                        }
                    </div>
                    <div className="col-sm-3 required">
                        <p>* Required field</p>
                    </div>
                </div>

                <div className="input-group mb-3 row">
                    <label htmlFor="first_name" className="col-sm-4 col-form-label form-label">First Name:</label>
                    <div className="col-sm-5">
                        <input 
                            type="text" 
                            name="first_name" 
                            placeholder="Enter value..." 
                            value={user_data.first_name} 
                            onChange={handleChange} 
                            className={'reg-input' + (submitted && !user_data.first_name ? ' is-invalid' : '')} 
                        />
                        {submitted && !user_data.first_name &&
                            <div className="invalid-feedback">First Name is required</div>
                        }
                    </div>
                    <div className="col-sm-3 required">
                        <p>* Required field</p>
                    </div>
                </div>

                <div className="input-group mb-3 row">
                    <label htmlFor="last_name" className="col-sm-4 col-form-label form-label">Last Name:</label>
                    <div className="col-sm-5">
                        <input 
                            type="text" 
                            name="last_name" 
                            placeholder="Enter value..." 
                            value={user_data.last_name} 
                            onChange={handleChange} 
                            className={'reg-input' + (submitted && !user_data.last_name ? ' is-invalid' : '')} 
                        />
                        {submitted && !user_data.last_name &&
                            <div className="invalid-feedback">Last Name is required</div>
                        }
                    </div>
                    <div className="col-sm-3 required">
                        <p>* Required field</p>
                    </div>
                </div>

                <div className="input-group mb-3 row">
                    <label htmlFor="username" className="col-sm-4 col-form-label form-label">Username:</label>
                    <div className="col-sm-5">
                        <input 
                            type="text" 
                            name="username" 
                            placeholder="Enter value..." 
                            value={user_data.username} 
                            onChange={handleChange} 
                            className={'reg-input' + (submitted && !user_data.username ? ' is-invalid' : '')} 
                        />
                        {submitted && !user_data.username &&
                            <div className="invalid-feedback">Username is required</div>
                        }
                    </div>
                    <div className="col-sm-3 required">
                        <p>* Required field</p>
                    </div>
                </div>

                <div className="input-group mb-3 row">
                    <label htmlFor="email" className="col-sm-4 col-form-label form-label">Email Address:</label>
                    <div className="col-sm-5">
                        <input 
                            type="email" 
                            name="email" 
                            placeholder="Enter value..." 
                            value={user_data.email} 
                            onChange={handleChange} 
                            className={'reg-input' + (submitted && !user_data.email ? ' is-invalid' : '')} 
                        />
                        {submitted && !user_data.email &&
                            <div className="invalid-feedback">Email Address is required</div>
                        }
                    </div>
                    <div className="col-sm-3 required">
                        <p>* Required field</p>
                    </div>
                </div>
                <div className="input-group mb-3 row">
                    <label htmlFor="password" className="col-sm-4 col-form-label form-label">Password:</label>
                    <div className="col-sm-5">
                        <input 
                            type="password" 
                            name="password" 
                            placeholder="Enter value..." 
                            value={user_data.password} 
                            onChange={handleChange} 
                            className={'reg-input' + (submitted && !user_data.password ? ' is-invalid' : '')} 
                        />
                        {submitted && !user_data.password &&
                            <div className="invalid-feedback">Password is required</div>
                        }
                    </div>
                    <div className="col-sm-3 required">
                        <p>* Required field</p>
                    </div>
                </div>
                <div className="input-group mb-3 row">
                    <label htmlFor="confirm_password" className="col-sm-4 col-form-label form-label">Password Confirmation:</label>
                    <div className="col-sm-5">
                        <input 
                            type="password" 
                            name="confirm_password" 
                            placeholder="Enter value..." 
                            value={user_data.confirm_password} 
                            onChange={handleChange} 
                            className={'reg-input' + (submitted && !user_data.confirm_password ? ' is-invalid' : '')} 
                        />
                        {submitted && !user_data.confirm_password &&
                            <div className="invalid-feedback">Password Confirmation is required</div>
                        }
                        <div style={{color: "#dc3545", fontSize: ".875em", marginTop: ".25rem", width: "100%"}}>{passwordMatch}</div>
                    </div>
                    <div className="col-sm-3 required">
                        <p>* Required field</p>
                    </div>
                </div>

                <br></br>
                <div className="row">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <button type="submit" className="btn btn-primary btn-sm btn-block dish-btn-primary">Submit</button>
                    </div>
                {/* /.col */}
                </div>
            </Form>
        </div>
    )
}
