import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { GetUserDetails, UpdateProfile } from "../redux/actions";
import { Header, Menu, UploadProfilePicture, Footer} from '../components';
import { Link, useHistory } from "react-router-dom";
import { Button, ListGroupItem } from 'react-bootstrap';
import SignaturePad from 'react-signature-canvas';
import { Success_Message, Error_Message } from "../shared";

import styles from './styles.module.css'

export const SignObligation = () => {
    let history = useHistory();
    const [user_data, setUser] = useState({
        username: localStorage.getItem("username"),
        signature: ''
    });
    let sigPad = {}

    const clear = () => {
        sigPad.clear()
    }

    useEffect(() => {
      
    }, [user_data])
    

    const getSignature = async () => {
        const trimmed   = sigPad.getTrimmedCanvas();
        const base64    = trimmed.toDataURL();
        saveSignature(base64);
    }

    const saveSignature = async (signature) => {
        const data = {
            username: localStorage.getItem("username"),
            signature: signature
        }
        document.getElementById("loader").style.display = "block";
        // const user_data1 = AesEncrypt(user_data, 'where do you go when you by yourself' );
        let res = await dispatch(UpdateProfile(data));
        document.getElementById("loader").style.display = "none";

        if(res.status === "success"){ 
            await Success_Message("Successful. Please upload documents.");
            setTimeout(function(){ 
                history.push('/registration-complete') 
            }, 3000);
            // window.location.href = '/upload-profile-picture'; 
        }else{
            Error_Message(res.data)
        }
    }

    const loadSignature = () => {
        let signatureImage = document.createElement("img");
        signatureImage.src = user_data.signature;
        // newImage.width = "150";
        // newImage.height = "150";
        document.querySelector("#imageContainer").innerHTML = signatureImage.outerHTML;
    }

    const dispatch = useDispatch();

    useEffect(() => {
        const getDetails = async () => {
            if(localStorage.getItem("username") === null){
                window.location.href = '/';
            }
            document.getElementById("loader").style.display = "block";
            const res = await dispatch(GetUserDetails(localStorage.getItem("username")));
            document.getElementById("loader").style.display = "none";
            let user_data1 = res.data
            if(user_data1 === null || user_data1 === ""){
                history.push('/');
            }
        }
        getDetails();
    }, []);

    return (
        
        <div>
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8 main-content">
                    <div className="container">
                        <Header/>
                        {/* <div className="header-login">
                            <Link to='/login'>Log In</Link>
                        </div>
                        <hr className="header-hr"></hr>
                        <Menu /> */}
                        <hr></hr>
                        <p className="page-title">
                            Sign Obligation
                        </p>
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-12">
                                {/* Profile Image */}
                                <div className="card card-primary card-outline">
                                    <div className='row'>
                                        <div className='col-md-1'></div>
                                        <div className='col-md-10'>
                                            <h5 style={{marginBottom: '35px'}}><strong>OBLIGATION</strong></h5>
                                            <p style={{textAlign: 'justify'}}>
                                                I, the undersigned, apply for admission to membership of Nigerian Association of Medical Physicists (NAMP) and declare that,  if accepted, I will be governed by the rules and code of ethics of the Association; and that while a member of the Association, I will advance the aims and objectives of the Association so far as shall be in my power. I also undertake that, I will cease to exercise any of  the privileges of membership on receipt of a notice from the association that, in accordance with rules and code of ethics, I have been declared to be no longer a member of the Association and that I will forthwith upon ceasing to be a member, return any property belonging to the Association or for which the Association is responsible, in my possession or entrusted to me.
                                            </p>

                                            <p style={{textAlign: 'justify'}}>
                                                I agree to comply with the Association’s policies and conditions, when I participate in the Association’s activities, and activities related with the Association from time to time.
                                            </p>

                                            <p style={{textAlign: 'justify'}}>
                                                I consent to the Association collecting, maintaining, using and disclosing my personal information in the manner set out in the association’s privacy policy on its constitution.
                                            </p>

                                            <p style={{textAlign: 'justify'}}>
                                                Please note that final acceptance into the membership of the Nigerian Association of Medical Physicists, regardless of level of admission, is conditional on payment of the relevant prescribed fees. The secretary will disclose dues payable, to successful candidates in writing or during meetings, within one month of her/his notification of successful election to the association membership.
                                            </p>
                                        </div>
                                        <div className='col-md-1'></div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-md-1'></div>
                                        <div className='col-md-10'>
                                            <h5 style={{marginLeft: '15px'}}>Sign with date in the box below</h5>
                                        </div>
                                    </div>
                                    <div className={styles.container}>
                                        <div className={styles.sigContainer}>
                                            <SignaturePad canvasProps={{className: styles.sigPad}}
                                            ref={(ref) => { sigPad = ref }} />

                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <Button block outline 
                                                        onClick={() => clear()}
                                                        style={{background: 'firebrick'}}
                                                    >Clear Board</Button>
                                                </div>
                                                <div className='col-md-2'></div>
                                                <div className='col-md-6'>
                                                    <Button block outline 
                                                        color="success"
                                                        onClick={() => getSignature()}
                                                        className="dish-btn-secondary"
                                                    >Submit Signated Copy</Button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
    )
}
