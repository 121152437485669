import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form } from 'react-bootstrap';
import { UpdateProfile } from "../redux/actions";
import { Success_Message, Error_Message } from "../shared";
import axios from 'axios';
import NaijaStates from 'naija-state-local-government';
import ReactInputDateMask from 'react-input-date-mask';

export const CompleteRegistration = () => {
    let res = {};
    let history = useHistory();
    // const General = useSelector((state) => state.General);
    const [authToken, setAuthToken] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [lgas, setLGA] = useState([])
    const [fieldActivity, setFieldActivity] = useState('')
    const [fieldActivityOther, setFieldActivityOther] = useState(false)
    const [LGADrowdown, setLGADrowdown] = useState(true)
    const [degreesObtained, setDegreesObtained] = useState([]);
    const [user_data, setUser] = useState({
        username: localStorage.getItem("username"),
        telephone: '',
        nationality: '',
        country_of_residence: '',
        district_province_state_of_origin: '',
        lga_of_origin: '',
        contact_address: '',
        dob: '',
        degrees_obtained: '',
        field_activity: ''
    });

    const [submitted, setSubmitted] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        getToken();
    }, [])

    const getToken = () => {
        const config = {
            headers: ({
                "Accept": "application/json",
                "api-token": "0-LfT03Ky6HzWVoVDT9Nj5jV7lkn7M2omvS-lBYEaZGhV1IvE3nbk2_9boqVlQo1X2Q",
                "user-email": "mendes.paul28@gmail.com"
            })
        };

        const url = "https://www.universal-tutorial.com/api/getaccesstoken";

        axios.get(url, config)
        .then(res=> {
            getCountries(res.data.auth_token)
            setAuthToken(res.data.auth_token)
        })
        .catch(err=> console.log(err))
    }

    const getCountries = (auth_token) => {
        const config = {
            headers: ({
                "Authorization": `Bearer ${auth_token}`,
                "Accept": "application/json"
            })
        };

        const url = "https://www.universal-tutorial.com/api/countries/";

        axios.get(url, config)
        .then(res=> {
            setCountries(res.data)
        })
        .catch(err=> console.log(err))
    }

    const getStates = (country) => {
        // if(country === 'Nigeria') {
            setLGADrowdown(true)
            const config = {
                headers: ({
                    "Authorization": `Bearer ${authToken}`,
                    "Accept": "application/json"
                })
            };

            const url = `https://www.universal-tutorial.com/api/states/${country}`;

            axios.get(url, config)
            .then(res=> {
                setStates(res.data)
            })
            .catch(err=> console.log(err))
        // }else {
        //     setLGADrowdown(false)
        // }
    }

    const getLGA = (state) => {
        const res = NaijaStates.lgas(state);
        setLGA(res.lgas)
    }

    function handleChange(e) {
        const { name, value } = e.target;
        if(name === 'nationality') {
            getStates(value)
            setUser(user_data => ({ ...user_data, [name]: value }));
        }else if(name === 'district_province_state_of_origin') {
            if(user_data.nationality === 'Nigeria') {
                setLGADrowdown(true)
                getLGA(value)
            }else {
                setLGADrowdown(false)
            }
            setUser(user_data => ({ ...user_data, [name]: value }));
        }else {
            setUser(user_data => ({ ...user_data, [name]: value }));
        }
    }

    function handleChangeDOB(e) {
        setUser(user_data => ({ ...user_data, dob: e }));
    }

    const handleChangeDegreesObtained = async (e) => {
        const { name, checked, value } = e.target;
        if(checked) {
            const exist = degreesObtained.includes(value);
            if(exist) {
                const index = degreesObtained.indexOf(value);
                degreesObtained.splice(index, 1);
                await await setUser(user_data => ({ ...user_data, degrees_obtained: JSON.stringify(degreesObtained) }));
            }else {
                degreesObtained.push(value);
                await await setUser(user_data => ({ ...user_data, degrees_obtained: JSON.stringify(degreesObtained) }));
            }
        }else {
            const index = degreesObtained.indexOf(value);
            degreesObtained.splice(index, 1);
            await await setUser(user_data => ({ ...user_data, degrees_obtained: JSON.stringify(degreesObtained) }));
        }
    }

    const handleChangeFieldActivity = (e) => {
        const { value } = e.target;
        if(value === 'others') {
            setFieldActivityOther(true);
            setUser(user_data => ({ ...user_data, field_activity: '' }));
        }else {
            setFieldActivityOther(false)
            setUser(user_data => ({ ...user_data, field_activity: value }));
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        let dd = user_data.dob.split('/')[0];
        let mm = user_data.dob.split('/')[1];
        let yy = user_data.dob.split('/')[2];
        let dob = dd+"-"+mm+"-"+yy;
        await setUser(user_data => ({ ...user_data, dob: dob }));
        
        setSubmitted(true);
        if ( user_data.telephone && user_data.nationality && user_data.country_of_residence && user_data.district_province_state_of_origin && user_data.lga_of_origin && user_data.contact_address && user_data.dob && degreesObtained.length !== 0 && user_data.field_activity) {
            document.getElementById("loader").style.display = "block";
            // const user_data1 = AesEncrypt(user_data, 'where do you go when you by yourself' );
            res = await dispatch(UpdateProfile(user_data));
            document.getElementById("loader").style.display = "none";

            if(res.status === "success"){ 
                await Success_Message("Successful. Please upload documents.");
                setTimeout(function(){ 
                    history.push('/upload-documents') 
                }, 3000);
                // window.location.href = '/upload-profile-picture'; 
            }else{
                Error_Message(res.data)
            }
        }
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>

                <div className="input-group mb-3 row">
                    <label htmlFor="telephone" className="col-sm-4 col-form-label form-label">Phone Number:</label>
                    <div className="col-sm-5">
                        <input 
                            type="text" 
                            name="telephone" 
                            placeholder="Enter value..." 
                            value={user_data.telephone} 
                            onChange={handleChange} 
                            className={'reg-input' + (submitted && !user_data.telephone ? ' is-invalid' : '')} 
                        />
                        {submitted && !user_data.telephone &&
                            <div className="invalid-feedback">Phone number is required</div>
                        }
                    </div>
                    <div className="col-sm-3 required">
                        <p>* Required field</p>
                    </div>
                </div>

                <div className="input-group mb-3 row">
                    <label htmlFor="country_of_residence" className="col-sm-4 col-form-label form-label">Country of Residence:</label>
                    <div className="col-sm-5">
                        <select 
                            className={'reg-input' + (submitted && !user_data.country_of_residence ? ' is-invalid' : '')}
                            value={user_data.country_of_residence} 
                            name="country_of_residence" 
                            onChange={handleChange} 
                        >
                            <option value="select">Choose...</option>
                            {countries && countries.map((country, index) => (
                                <option 
                                    key={index}
                                    value={country.country_name}
                                >{country.country_name}
                                </option>
                            ))}
                        </select>
                        {submitted && !user_data.country_of_residence &&
                            <div className="invalid-feedback">Country of Residence is required</div>
                        }
                    </div>
                    <div className="col-sm-3 required">
                        <p>* Required field</p>
                    </div>
                </div>

                <div className="input-group mb-3 row">
                    <label htmlFor="nationality" className="col-sm-4 col-form-label form-label">Nationality:</label>
                    <div className="col-sm-5">
                        <select 
                            className={'reg-input' + (submitted && !user_data.nationality ? ' is-invalid' : '')}
                            value={user_data.nationality} 
                            name="nationality" 
                            onChange={handleChange} 
                        >
                            <option value="select">Choose...</option>
                            {countries && countries.map((country, index) => (
                                <option 
                                    key={index}
                                    value={country.country_name}
                                >{country.country_name}
                                </option>
                            ))}
                        </select>
                        {submitted && !user_data.nationality &&
                            <div className="invalid-feedback">Nationality is required</div>
                        }
                    </div>
                    <div className="col-sm-3 required">
                        <p>* Required field</p>
                    </div>
                </div>

                <div className="input-group mb-3 row">
                    <label htmlFor="district_province_state_of_origin" className="col-sm-4 col-form-label form-label">District/Province/State of Origin:</label>
                    <div className="col-sm-5">
                        <select 
                            className={'reg-input' + (submitted && !user_data.district_province_state_of_origin ? ' is-invalid' : '')}
                            value={user_data.district_province_state_of_origin} 
                            name="district_province_state_of_origin" 
                            onChange={handleChange} 
                        >
                            <option value="select">Choose...</option>
                            {states && states.map((state, index) => (
                                <option 
                                    key={index}
                                    value={state.state_name}
                                >{state.state_name}
                                </option>
                            ))}
                        </select>
                        {submitted && !user_data.district_province_state_of_origin &&
                            <div className="invalid-feedback">State is required</div>
                        }
                    </div>
                    <div className="col-sm-3 required">
                        <p>* Required field</p>
                    </div>
                </div>

                <div className="input-group mb-3 row">
                    <label htmlFor="lga_of_origin" className="col-sm-4 col-form-label form-label">Local Government of Origin:</label>
                    {LGADrowdown ?   
                        <div className="col-sm-5">
                            <select 
                                className={'reg-input' + (submitted && !user_data.lga_of_origin ? ' is-invalid' : '')}
                                value={user_data.lga_of_origin} 
                                name="lga_of_origin" 
                                onChange={handleChange} 
                            >
                                <option value="select">Choose...</option>
                                {lgas && lgas.map((lga, index) => (
                                    <option 
                                        key={index}
                                        value={lga}
                                    >{lga}
                                    </option>
                                ))}
                            </select>
                            {submitted && !user_data.lga_of_origin &&
                                <div className="invalid-feedback">LGA is required</div>
                            }
                        </div>
                    :
                        <div className="col-sm-5">
                            <input 
                                type="text" 
                                name="lga_of_origin" 
                                placeholder="Enter value..." 
                                value={user_data.lga_of_origin} 
                                onChange={handleChange} 
                                className={'reg-input' + (submitted && !user_data.lga_of_origin ? ' is-invalid' : '')} 
                            />
                            {submitted && !user_data.lga_of_origin &&
                                <div className="invalid-feedback">LGA is required</div>
                            }
                        </div>
                    }
                    <div className="col-sm-3 required">
                        <p>* Required field</p>
                    </div>
                </div>

                <div className="input-group mb-3 row">
                    <label htmlFor="contact_address" className="col-sm-4 col-form-label form-label">Contact Address:</label>
                    <div className="col-sm-5">
                        <textarea 
                            className={'reg-input' + (submitted && !user_data.contact_address ? ' is-invalid' : '')} 
                            rows={3} 
                            name="contact_address" 
                            value={user_data.contact_address} 
                            onChange={handleChange} 
                            placeholder="Enter ..." 
                            // defaultValue={""} 
                        />
                        {submitted && !user_data.contact_address &&
                            <div className="invalid-feedback">Contact Address is required</div>
                        }
                    </div>
                    <div className="col-sm-3 required">
                        <p>* Required field</p>
                    </div>
                </div>

                <div className="input-group mb-3 row">
                    <label htmlFor="dob" className="col-sm-4 col-form-label form-label">Date of Birth:</label>
                    <div className="col-sm-5">
                        <ReactInputDateMask  
                            mask='dd/mm/yyyy' 
                            showMaskOnFocus={false}  
                            className={'reg-input' + (submitted && !user_data.dob ? ' is-invalid' : '')} 
                            name="dob" 
                            value={user_data.dob} 
                            onChange={e => handleChangeDOB(e)}
                            showMaskOnHover={true} 
                            placeholder="enter date"
                            // id={"document_date-"+i}
                        />
                        {submitted && !user_data.dob &&
                            <div className="invalid-feedback">Date of Birth is required</div>
                        }
                    </div>
                    <div className="col-sm-3 required">
                        <p>* Required field</p>
                    </div>
                </div>

                <div className="input-group mb-3 row">
                    <label htmlFor="degrees_obtained" className="col-sm-4 col-form-label form-label">Degrees Obtained:</label>
                    <div className="col-sm-5">
                        <div className="col-sm-12">
                            <input 
                                type="checkbox" 
                                name="bachelor_of_science_technology" 
                                placeholder="Enter value..." 
                                value='Bachelor of Science/Technology'
                                onChange={handleChangeDegreesObtained} 
                                // className={'reg-input'} 
                                className="form-check-input"
                            />
                            <label htmlFor="bachelor_of_science_technology" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Bachelor of Science/Technology</label>
                        </div>
                        <div className="col-sm-12">
                            <input 
                                type="checkbox" 
                                name="master_of_science_technology" 
                                placeholder="Enter value..." 
                                value='Master of Science/Technology'
                                onChange={handleChangeDegreesObtained} 
                                // className={'reg-input'} 
                                className="form-check-input"
                            />
                            <label htmlFor="master_of_science_technology" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Master of Science/Technology</label>
                        </div>
                        <div className="col-sm-12">
                            <input 
                                type="checkbox" 
                                name="doctor_of_philosophy" 
                                placeholder="Enter value..." 
                                value='Doctor of Philosophy'
                                onChange={handleChangeDegreesObtained} 
                                // className={'reg-input'} 
                                className="form-check-input"
                            />
                            <label htmlFor="doctor_of_philosophy" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Doctor of Philosophy</label>
                        </div>
                    </div>
                    <div className="col-sm-3 required">
                        <p>* Required field</p>
                    </div>
                </div>

                <div className="input-group mb-3 row">
                    <label htmlFor="field_activity" className="col-sm-4 col-form-label form-label">Field of Activity:</label>
                    <div className="col-sm-5">
                        <div className="col-sm-12">
                            <input 
                                type="radio" 
                                name="field_activity" 
                                placeholder="Enter value..." 
                                value='Academic'
                                onChange={handleChangeFieldActivity} 
                                // className={'reg-input'} 
                                className="form-check-input"
                            />
                            <label htmlFor="academic" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Academic</label>
                        </div>
                        <div className="col-sm-12">
                            <input 
                                type="radio" 
                                name="field_activity" 
                                placeholder="Enter value..." 
                                value='Diagnostic Radiology'
                                onChange={handleChangeFieldActivity} 
                                // className={'reg-input'} 
                                className="form-check-input"
                            />
                            <label htmlFor="diagnostic_radiology" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Diagnostic Radiology</label>
                        </div>
                        <div className="col-sm-12">
                            <input 
                                type="radio" 
                                name="field_activity" 
                                placeholder="Enter value..." 
                                value='Nuclear Medicine'
                                onChange={handleChangeFieldActivity} 
                                // className={'reg-input'} 
                                className="form-check-input"
                            />
                            <label htmlFor="nuclear_medicine" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Nuclear Medicine</label>
                        </div>
                        <div className="col-sm-12">
                            <input 
                                type="radio" 
                                name="field_activity" 
                                placeholder="Enter value..." 
                                value='Radiotherapy'
                                onChange={handleChangeFieldActivity} 
                                // className={'reg-input'} 
                                className="form-check-input"
                            />
                            <label htmlFor="radiotherapy" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Radiotherapy</label>
                        </div>
                        <div className="col-sm-12">
                            <input 
                                type="radio" 
                                name="field_activity" 
                                placeholder="Enter value..." 
                                value='Student'
                                onChange={handleChangeFieldActivity} 
                                // className={'reg-input'} 
                                className="form-check-input"
                            />
                            <label htmlFor="student" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Student</label>
                        </div>
                        <div className="col-sm-12">
                            <input 
                                type="radio" 
                                name="field_activity" 
                                placeholder="Enter value..." 
                                value='others'
                                onChange={handleChangeFieldActivity} 
                                // className={'reg-input'} 
                                className="form-check-input"
                            />
                            <label htmlFor="others" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Others(specify)</label>
                        </div>
                    </div>
                    <div className="col-sm-3 required">
                        <p>* Required field</p>
                    </div>
                </div>
                {fieldActivityOther ? 
                    <div className="input-group mb-3 row">
                        <div className="col-sm-4"></div>
                        <div className="col-sm-5">
                            <input 
                                type="text" 
                                name="field_activity" 
                                placeholder="Enter value..." 
                                value={user_data.field_activity} 
                                onChange={handleChange} 
                                className={'reg-input'} 
                            />
                        </div>
                    </div>
                : ''}

                <br></br>
                <div className="row">
                    <div className="col-4"></div>
                    <div className="col-4">
                        <button type="submit" className="btn btn-primary btn-sm btn-block dish-btn-primary">Submit</button>
                    </div>
                {/* /.col */}
                </div>
            </Form>
        </div>
    )
}
