import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form } from 'react-bootstrap';
import { UserSignin } from "../redux/actions";
import { AesEncrypt } from 'aes';
import { Success_Message, Error_Message } from "../shared";

export const SigninForm = () => {
    let res = {};

    const [user_data, setUser] = useState({
        username: '',
        password: '',
    });

    const [submitted, setSubmitted] = useState(false);
    const dispatch = useDispatch();

    function handleChange(e) {
        const { name, value } = e.target;
        setUser(user_data => ({ ...user_data, [name]: value }));
    }

    async function handleSubmit(e) {
        e.preventDefault();
        
        setSubmitted(true);
        if ( user_data.username && user_data.password ) {
            const user_data1 = AesEncrypt(user_data, 'where do you go when you by yourself' );
            document.getElementById("loader").style.display = "block";
            res = await dispatch(UserSignin(user_data1));
            document.getElementById("loader").style.display = "none";

            if(res.status === "success"){ 
                localStorage.setItem("user_data", JSON.stringify(res.data))
                localStorage.setItem("username", user_data.username);
                localStorage.setItem("isAuth", true);
                
                await Success_Message("Login successful") 
                // history.push('/facial-verification')
                window.location.href = '/profile'; 
            }else{
                Error_Message(res.data)
            }
        }
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                
                <div className="input-group mb-3 row">
                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label form-label">Username:</label>
                    <div className="col-sm-5">
                        <input 
                            type="text" 
                            name="username" 
                            placeholder="Enter value..." 
                            value={user_data.username} 
                            onChange={handleChange} 
                            className={'reg-input' + (submitted && !user_data.username ? ' is-invalid' : '')} 
                        />
                        {submitted && !user_data.username &&
                            <div className="invalid-feedback">Username is required</div>
                        }
                    </div>
                </div>
                <div className="input-group mb-3 row">
                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label form-label">Password:</label>
                    <div className="col-sm-5">
                        <input 
                            type="password" 
                            name="password" 
                            placeholder="Enter value..." 
                            value={user_data.password} 
                            onChange={handleChange} 
                            className={'reg-input' + (submitted && !user_data.password ? ' is-invalid' : '')} 
                        />
                        {submitted && !user_data.password &&
                            <div className="invalid-feedback">Password is required</div>
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-4">
                        <button type="submit" className="btn btn-primary btn-sm btn-block dish-btn-primary">Submit</button>
                    </div>
                {/* /.col */}
                </div>
            </Form>
        </div>
    )
}
