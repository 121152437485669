import React from 'react';
import { Link } from "react-router-dom";

export const Footer = () => {
    return (
        <div className="row footer-row">
            <div className="col-md-12">
                <p>
                    Medical Physics unit, Department of Radiation Oncology, 
                    University College Hospital, 
                    Queen Elizabeth Road, 
                    Ibadan, Nigeria.
                    PMB 5116.
                </p>
                <p>info@namp.ng</p>
            </div>
            {/* <div className="col-md-3">
                <Link to="#">Terms and Conditions</Link><br></br>
                <Link to="#">Privacy Policy</Link><br></br>
                <Link to="#">Cookies</Link><br></br>
                <Link to="#">Disclaimer</Link>
            </div>
            <div className="col-md-3">
                <Link to="#">Home</Link><br></br>
                <Link to="#">Contact Us</Link><br></br>
                <Link to="#">Feedback from you</Link><br></br>
                <Link to="https://integra.websitewelcome.com:2096/">Staff Email</Link>
            </div> */}
        </div>
    )
}
