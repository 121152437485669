import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col, Button } from 'react-bootstrap';
import { Success_Message, Error_Message } from "../shared";
import { GetCountries, GetUserDetails, UpdateProfile } from "../redux/actions";
import axios from 'axios';
import NaijaStates from 'naija-state-local-government';
import ReactInputDateMask from 'react-input-date-mask';

export const PersonalDetails = (props) => {
    const dispatch  = useDispatch();
    let res = {};
    const [authToken, setAuthToken] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [lgas, setLGA] = useState([])
    const [fieldActivity, setFieldActivity] = useState('')
    const [fieldActivityOther, setFieldActivityOther] = useState(false)
    const [LGADrowdown, setLGADrowdown] = useState(true)
    const [user_data1, setUser] = useState([]);
    const [degreesObtained, setDegreesObtained] = useState([]);
    const [bscChecked, setBscChecked] = useState(false);
    const [mscChecked, setMscChecked] = useState(false);
    const [drChecked, setDrChecked] = useState(false);

    const [submitted, setSubmitted] = useState(false);
    // const General = useSelector((state) => state.General);
    // const { countries }  = General;
    // useEffect(() => {
    //     getToken();
    // }, []);

    useEffect(() => {
        getToken();
    }, []);

    const getToken = () => {
        const config = {
            headers: ({
                "Accept": "application/json",
                "api-token": "0-LfT03Ky6HzWVoVDT9Nj5jV7lkn7M2omvS-lBYEaZGhV1IvE3nbk2_9boqVlQo1X2Q",
                "user-email": "mendes.paul28@gmail.com"
            })
        };

        const url = "https://www.universal-tutorial.com/api/getaccesstoken";

        axios.get(url, config)
        .then(res=> {
            getCountries(res.data.auth_token);
            setAuthToken(res.data.auth_token);
            getDetails(res.data.auth_token);
        })
        .catch(err=> console.log(err))
    }

    const getDetails = async(auth_token) => {
        document.getElementById("loader").style.display = "block";
        await dispatch(GetCountries());
        const res = await dispatch(GetUserDetails(JSON.parse(localStorage.getItem("user_data")).username));
        await setUser(res.data)
        await getStates(res.data.nationality, auth_token);
        await getLGA(res.data.district_province_state_of_origin);
        await degreesCheck(res.data.degrees_obtained);
        await fieldActivitySelect(res.data.field_activity);
        document.getElementById("loader").style.display = "none";
    }

    

    const degreesCheck = async(degrees) => {
        const degreesArray = JSON.parse(degrees);
        const bscExist = degreesArray.includes('Bachelor of Science/Technology');
        if(bscExist) {
            setBscChecked(true);
        }

        const mscExist = degreesArray.includes('Master of Science/Technology');
        if(mscExist) {
            setMscChecked(true);
        }

        const drExist = degreesArray.includes('Doctor of Philosophy');
        if(drExist) {
            setDrChecked(true);
        }

        // setUser(user_data1 => ({ ...user_data1, degrees_obtained: degreesArray }));
    }

    const fieldActivitySelect = (field_activity) => {
        let elements = document.getElementsByName('field_activity');
        for (let i=0; i<elements.length; i++) {
        if(elements[i].value === field_activity) {
            elements[i].checked = true;
        }
        }
    }

    const getCountries = (auth_token) => {
        const config = {
            headers: ({
                "Authorization": `Bearer ${auth_token}`,
                "Accept": "application/json"
            })
        };

        const url = "https://www.universal-tutorial.com/api/countries/";

        axios.get(url, config)
        .then(res=> {
            setCountries(res.data)
        })
        .catch(err=> console.log(err))
    }

    const getStates = (country, auth_token) => {
        // if(country === 'Nigeria') {
            setLGADrowdown(true)
            const config = {
                headers: ({
                    "Authorization": `Bearer ${auth_token}`,
                    "Accept": "application/json"
                })
            };

            const url = `https://www.universal-tutorial.com/api/states/${country}`;

            axios.get(url, config)
            .then(res=> {
                setStates(res.data)
            })
            .catch(err=> console.log(err))
        // }else {
        //     setLGADrowdown(false)
        // }
    }

    const getLGA = (state) => {
        const res = NaijaStates.lgas(state);
        setLGA(res.lgas)
    }

    function handleChange(e) {
        const { name, value } = e.target;
        if(name === 'nationality') {
            getStates(value, authToken)
            setUser(user_data1 => ({ ...user_data1, [name]: value }));
        }else if(name === 'district_province_state_of_origin') {
            if(user_data1.nationality === 'Nigeria') {
                setLGADrowdown(true)
                getLGA(value)
            }else {
                setLGADrowdown(false)
            }
            setUser(user_data1 => ({ ...user_data1, [name]: value }));
        }else {
            setUser(user_data1 => ({ ...user_data1, [name]: value }));
        }
    }

    function handleChangeDOB(e) {
        setUser(user_data1 => ({ ...user_data1, dob: e }));
    }

    const handleChangeDegreesObtained = async (e) => {
        const { name, checked, value } = e.target;
        if(checked) {
            const exist = degreesObtained.includes(value);
            if(exist) {
                const index = degreesObtained.indexOf(value);
                degreesObtained.splice(index, 1);
                await await setUser(user_data => ({ ...user_data, degrees_obtained: JSON.stringify(degreesObtained) }));
            }else {
                degreesObtained.push(value);
                await await setUser(user_data => ({ ...user_data, degrees_obtained: JSON.stringify(degreesObtained) }));
            }
        }else {
            const index = degreesObtained.indexOf(value);
            degreesObtained.splice(index, 1);
            await await setUser(user_data => ({ ...user_data, degrees_obtained: JSON.stringify(degreesObtained) }));
        }

        if(name === 'bachelor_of_science_technology') {
            setBscChecked(!bscChecked);
        }else if(name === 'master_of_science_technology') {
            setMscChecked(!mscChecked);
        }else if(name === 'doctor_of_philosophy') {
            setDrChecked(!drChecked);
        }
    }

    const handleChangeFieldActivity = (e) => {
        const { value } = e.target;
        if(value === 'others') {
            setFieldActivityOther(true);
            setUser(user_data1 => ({ ...user_data1, field_activity: '' }));
        }else {
            setFieldActivityOther(false)
            setUser(user_data1 => ({ ...user_data1, field_activity: value }));
        }
    }

    const submitPersonalDetails = async (e) => {
        e.preventDefault();
        let dd = user_data1.dob.split('/')[0];
        let mm = user_data1.dob.split('/')[1];
        let yy = user_data1.dob.split('/')[2];
        let dob = dd+"-"+mm+"-"+yy;
        setUser(user_data1 => ({ ...user_data1, dob: dob }));

        setSubmitted(true);
        if ( user_data1.first_name && user_data1.last_name && user_data1.middle_name && user_data1.telephone && user_data1.gender && user_data1.nationality && user_data1.country_of_residence && user_data1.district_province_state_of_origin && user_data1.contact_address && user_data1.dob && user_data1.degrees_obtained.length && user_data1.field_activity ) {
            document.getElementById("loader").style.display = "block";
            res = await dispatch(UpdateProfile(user_data1));
            document.getElementById("loader").style.display = "none";
            if(res.status === "success"){ 
                await Success_Message("Update successful.");
                // history.push('/profile')
                window.location.reload(false)
            }else{
                Error_Message(res.data)
            }
        }
    }

    return (
        <div>
            <form onSubmit={submitPersonalDetails}>
                <Row style={{marginBottom: "25px"}}>
                    <Col md={1} xs={12}></Col>
                    <Col md={10} xs={12}>
                        {/* First name */}
                        <div className="input-group mb-3 row">
                            <label htmlFor="first_name" className="col-sm-4 col-form-label form-label">First Name:</label>
                            <div className="col-sm-8">
                                <input 
                                    type="text" 
                                    name="first_name" 
                                    placeholder="Enter value..." 
                                    value={user_data1.first_name} 
                                    onChange={handleChange} 
                                    className={'profile-input' + (submitted && !user_data1.first_name ? ' is-invalid' : '')} 
                                />
                                {submitted && !user_data1.first_name &&
                                    <div className="invalid-feedback">First Name is required</div>
                                }
                            </div>
                        </div>
                        {/* Last name */}
                        <div className="input-group mb-3 row">
                            <label htmlFor="last_name" className="col-sm-4 col-form-label form-label">Last Name:</label>
                            <div className="col-sm-8">
                                <input 
                                    type="text" 
                                    name="last_name" 
                                    placeholder="Enter value..." 
                                    value={user_data1.last_name} 
                                    onChange={handleChange} 
                                    className={'profile-input' + (submitted && !user_data1.last_name ? ' is-invalid' : '')} 
                                />
                                {submitted && !user_data1.last_name &&
                                    <div className="invalid-feedback">First Name is required</div>
                                }
                            </div>
                        </div>
                        {/* Middle name */}
                        <div className="input-group mb-3 row">
                            <label htmlFor="middle_name" className="col-sm-4 col-form-label form-label">Middle Name:</label>
                            <div className="col-sm-8">
                                <input 
                                    type="text" 
                                    name="middle_name" 
                                    placeholder="Enter value..." 
                                    defaultValue={user_data1.middle_name} 
                                    onChange={handleChange} 
                                    className={'profile-input' + (submitted && !user_data1.middle_name ? ' is-invalid' : '')} 
                                />
                                {submitted && !user_data1.middle_name &&
                                    <div className="invalid-feedback">Middle Name is required</div>
                                }
                            </div>
                        </div>
                        {/* Phone number */}
                        {/* <div className="input-group mb-3 row">
                            <label htmlFor="zip_code" className="col-sm-4 col-form-label form-label">Zip Code:</label>
                            <div className="col-sm-8">
                                <select 
                                    className={'profile-input' + (submitted && !user_data1.zip_code ? ' is-invalid' : '')}
                                    value={user_data1.zip_code} 
                                    name="zip_code" 
                                    onChange={handleChange} 
                                >
                                    <option value="select">Choose...</option>
                                    {countries && countries.map((country, index) => (
                                        <option 
                                            key={index}
                                            value={country.zip_code}
                                        >{country.zip_code}
                                        </option>
                                    ))}
                                </select>
                                {submitted && !user_data1.zip_code &&
                                    <div className="invalid-feedback">Zip Code is required</div>
                                }
                            </div>
                        </div> */}

                        <div className="input-group mb-3 row">
                            <label htmlFor="telephone" className="col-sm-4 col-form-label form-label">Phone Number:</label>
                            <div className="col-sm-8">
                                <input 
                                    type="text" 
                                    name="telephone" 
                                    placeholder="Enter value..." 
                                    value={user_data1.telephone} 
                                    onChange={handleChange} 
                                    className={'profile-input' + (submitted && !user_data1.telephone ? ' is-invalid' : '')} 
                                />
                                {submitted && !user_data1.telephone &&
                                    <div className="invalid-feedback">Phone number is required</div>
                                }
                            </div>
                        </div>
                        {/* Gender */}
                        <div className="input-group mb-3 row">
                            <label htmlFor="gender" className="col-sm-4 col-form-label form-label">Gender:</label>
                            <div className="col-sm-8">
                                <select 
                                    className={'profile-input' + (submitted && !user_data1.gender ? ' is-invalid' : '')}
                                    value={user_data1.gender} 
                                    name="gender" 
                                    onChange={handleChange} 
                                >
                                    <option value="select">Choose...</option>
                                    <option value="male" >Male</option>
                                    <option value="female" >Female</option>
                                    <option value="prefer_not_to_say">Prefer not to say</option>
                                    <option value="others" >Others</option>
                                </select>
                                {submitted && !user_data1.gender &&
                                    <div className="invalid-feedback">Gender is required</div>
                                }
                            </div>
                        </div>
                        {/* Country of Residence */}
                        <div className="input-group mb-3 row">
                            <label htmlFor="country_of_residence" className="col-sm-4 col-form-label form-label">Country of Residence:</label>
                            <div className="col-sm-8">
                                <select 
                                    className={'profile-input' + (submitted && !user_data1.country_of_residence ? ' is-invalid' : '')}
                                    value={user_data1.country_of_residence} 
                                    name="country_of_residence" 
                                    onChange={handleChange} 
                                >
                                    <option value="select">Choose...</option>
                                    {countries && countries.map((country, index) => (
                                        <option 
                                            key={index}
                                            value={country.country_name}
                                        >{country.country_name}
                                        </option>
                                    ))}
                                </select>
                                {submitted && !user_data1.country_of_residence &&
                                    <div className="invalid-feedback">Country of Residence is required</div>
                                }
                            </div>
                        </div>
                        {/* Nationality */}
                        <div className="input-group mb-3 row">
                            <label htmlFor="nationality" className="col-sm-4 col-form-label form-label">Nationality:</label>
                            <div className="col-sm-8">
                                <select 
                                    className={'profile-input' + (submitted && !user_data1.nationality ? ' is-invalid' : '')}
                                    value={user_data1.nationality} 
                                    name="nationality" 
                                    onChange={handleChange} 
                                >
                                    <option value="select">Choose...</option>
                                    {countries && countries.map((country, index) => (
                                        <option 
                                            key={index}
                                            value={country.country_name}
                                        >{country.country_name}
                                        </option>
                                    ))}
                                </select>
                                {submitted && !user_data1.nationality &&
                                    <div className="invalid-feedback">Nationality is required</div>
                                }
                            </div>
                        </div>
                        {/* District Province State */}
                        <div className="input-group mb-3 row">
                            <label htmlFor="district_province_state_of_origin" className="col-sm-4 col-form-label form-label">District/Province/State of Origin:</label>
                            <div className="col-sm-8">
                                {/* <input 
                                    type="text" 
                                    name="district_province_state_of_origin" 
                                    placeholder="Enter value..." 
                                    value={user_data1.district_province_state_of_origin} 
                                    onChange={handleChange} 
                                    className={'profile-input' + (submitted && !user_data1.district_province_state_of_origin ? ' is-invalid' : '')} 
                                /> */}
                                <select 
                                    className={'reg-input' + (submitted && !user_data1.district_province_state_of_origin ? ' is-invalid' : '')}
                                    value={user_data1.district_province_state_of_origin} 
                                    name="district_province_state_of_origin" 
                                    onChange={handleChange} 
                                >
                                    <option value="select">Choose...</option>
                                    {states && states.map((state, index) => (
                                        <option 
                                            key={index}
                                            value={state.state_name}
                                        >{state.state_name}
                                        </option>
                                    ))}
                                </select>
                                {submitted && !user_data1.district_province_state_of_origin &&
                                    <div className="invalid-feedback">District/Province/State is required</div>
                                }
                            </div>
                        </div>
                    
                        {/* Local Government of Origin */}
                        <div className="input-group mb-3 row">
                            <label htmlFor="lga_of_origin" className="col-sm-4 col-form-label form-label">Local Government of Origin:</label>
                            {LGADrowdown ?   
                                <div className="col-sm-8">
                                    <select 
                                        className={'reg-input' + (submitted && !user_data1.lga_of_origin ? ' is-invalid' : '')}
                                        value={user_data1.lga_of_origin} 
                                        name="lga_of_origin" 
                                        onChange={handleChange} 
                                    >
                                        <option value="select">Choose...</option>
                                        {lgas && lgas.map((lga, index) => (
                                            <option 
                                                key={index}
                                                value={lga}
                                            >{lga}
                                            </option>
                                        ))}
                                    </select>
                                    {submitted && !user_data1.lga_of_origin &&
                                        <div className="invalid-feedback">LGA is required</div>
                                    }
                                </div>
                            :
                                <div className="col-sm-8">
                                    <input 
                                        type="text" 
                                        name="lga_of_origin" 
                                        placeholder="Enter value..." 
                                        value={user_data1.lga_of_origin} 
                                        onChange={handleChange} 
                                        className={'reg-input' + (submitted && !user_data1.lga_of_origin ? ' is-invalid' : '')} 
                                    />
                                    {submitted && !user_data1.lga_of_origin &&
                                        <div className="invalid-feedback">LGA is required</div>
                                    }
                                </div>
                            }
                        </div>

                        <div className="input-group mb-3 row">
                            <label htmlFor="contact_address" className="col-sm-4 col-form-label form-label">Contact Address:</label>
                            <div className="col-sm-8">
                                <textarea 
                                    className={'reg-input' + (submitted && !user_data1.contact_address ? ' is-invalid' : '')} 
                                    rows={3} 
                                    name="contact_address" 
                                    value={user_data1.contact_address} 
                                    onChange={handleChange} 
                                    placeholder="Enter ..." 
                                    // defaultValue={""} 
                                />
                                {submitted && !user_data1.contact_address &&
                                    <div className="invalid-feedback">Contact Address is required</div>
                                }
                            </div>
                        </div>

                        <div className="input-group mb-3 row">
                            <label htmlFor="dob" className="col-sm-4 col-form-label form-label">Date of Birth:</label>
                            <div className="col-sm-8">
                                <ReactInputDateMask  
                                    mask='dd/mm/yyyy' 
                                    showMaskOnFocus={false}  
                                    className={'reg-input' + (submitted && !user_data1.dob ? ' is-invalid' : '')} 
                                    name="dob" 
                                    value={user_data1.dob} 
                                    onChange={e => handleChangeDOB(e)}
                                    showMaskOnHover={true} 
                                    placeholder="enter date"
                                    // id={"document_date-"+i}
                                />
                                {submitted && !user_data1.dob &&
                                    <div className="invalid-feedback">Date of Birth is required</div>
                                }
                            </div>
                        </div>

                        <div className="input-group mb-3 row">
                            <label htmlFor="degrees_obtained" className="col-sm-4 col-form-label form-label">Degrees Obtained:</label>
                            <div className="col-sm-8">
                                <div className="col-sm-12">
                                    <input 
                                        type="checkbox" 
                                        name="bachelor_of_science_technology" 
                                        placeholder="Enter value..." 
                                        value='Bachelor of Science/Technology'
                                        onChange={handleChangeDegreesObtained} 
                                        // checked={props.profileDetails.student_data1.disability_dyslexic === "true" ?  true : false}
                                        checked={bscChecked}
                                        // className={'reg-input'} 
                                        className="form-check-input"
                                    />
                                    <label htmlFor="bachelor_of_science_technology" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Bachelor of Science/Technology</label>
                                </div>
                                <div className="col-sm-12">
                                    <input 
                                        type="checkbox" 
                                        name="master_of_science_technology" 
                                        placeholder="Enter value..." 
                                        value='Master of Science/Technology'
                                        onChange={handleChangeDegreesObtained} 
                                        checked={mscChecked}
                                        // className={'reg-input'} 
                                        className="form-check-input"
                                    />
                                    <label htmlFor="master_of_science_technology" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Master of Science/Technology</label>
                                </div>
                                <div className="col-sm-12">
                                    <input 
                                        type="checkbox" 
                                        name="doctor_of_philosophy" 
                                        placeholder="Enter value..." 
                                        value='Doctor of Philosophy'
                                        onChange={handleChangeDegreesObtained} 
                                        checked={drChecked}
                                        // className={'reg-input'} 
                                        className="form-check-input"
                                    />
                                    <label htmlFor="doctor_of_philosophy" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Doctor of Philosophy</label>
                                </div>
                            </div>
                        </div>

                        <div className="input-group mb-3 row">
                            <label htmlFor="field_activity" className="col-sm-4 col-form-label form-label">Field of Activity:</label>
                            <div className="col-sm-8">
                                <div className="col-sm-12">
                                    <input 
                                        type="radio" 
                                        name="field_activity" 
                                        placeholder="Enter value..." 
                                        value='Academic'
                                        onChange={handleChangeFieldActivity} 
                                        // className={'reg-input'} 
                                        className="form-check-input"
                                    />
                                    <label htmlFor="academic" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Academic</label>
                                </div>
                                <div className="col-sm-12">
                                    <input 
                                        type="radio" 
                                        name="field_activity" 
                                        placeholder="Enter value..." 
                                        value='Diagnostic Radiology'
                                        onChange={handleChangeFieldActivity} 
                                        // className={'reg-input'} 
                                        className="form-check-input"
                                    />
                                    <label htmlFor="diagnostic_radiology" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Diagnostic Radiology</label>
                                </div>
                                <div className="col-sm-12">
                                    <input 
                                        type="radio" 
                                        name="field_activity" 
                                        placeholder="Enter value..." 
                                        value='Nuclear Medicine'
                                        onChange={handleChangeFieldActivity} 
                                        // className={'reg-input'} 
                                        className="form-check-input"
                                    />
                                    <label htmlFor="nuclear_medicine" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Nuclear Medicine</label>
                                </div>
                                <div className="col-sm-12">
                                    <input 
                                        type="radio" 
                                        name="field_activity" 
                                        placeholder="Enter value..." 
                                        value='Radiotherapy'
                                        onChange={handleChangeFieldActivity} 
                                        // className={'reg-input'} 
                                        className="form-check-input"
                                    />
                                    <label htmlFor="radiotherapy" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Radiotherapy</label>
                                </div>
                                <div className="col-sm-12">
                                    <input 
                                        type="radio" 
                                        name="field_activity" 
                                        placeholder="Enter value..." 
                                        value='Student'
                                        onChange={handleChangeFieldActivity} 
                                        // className={'reg-input'} 
                                        className="form-check-input"
                                    />
                                    <label htmlFor="student" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Student</label>
                                </div>
                                <div className="col-sm-12">
                                    <input 
                                        type="radio" 
                                        name="field_activity" 
                                        placeholder="Enter value..." 
                                        value='others'
                                        onChange={handleChangeFieldActivity} 
                                        // className={'reg-input'} 
                                        className="form-check-input"
                                    />
                                    <label htmlFor="others" className="col-sm-11 col-form-label form-label" style={{paddingLeft: '2px', marginTop: '-3px'}}>Others(specify)</label>
                                </div>
                            </div>
                        </div>
                        {fieldActivityOther ? 
                            <div className="input-group mb-3 row">
                                <div className="col-sm-4"></div>
                                <div className="col-sm-8">
                                    <input 
                                        type="text" 
                                        name="field_activity" 
                                        placeholder="Enter value..." 
                                        value={user_data1.field_activity} 
                                        onChange={handleChange} 
                                        className={'reg-input'} 
                                    />
                                </div>
                            </div>
                        : ''}

                            </Col>
                            <Col md={1} xs={12}></Col>
                        </Row>
                {/* <button type="submit" class="btn btn-block bg-gradient-success btn-sm">Update Personal Details</button> */}
                <Row>
                    <Col md={1} xs={12}></Col>
                    <Col md={10} xs={12}>
                        <Button block outline 
                            type="submit"
                            color="success"
                            className="mydas-btn-secondary"
                            style={{ float: "right", marginRight: "20px" }} 
                        >Update Personal Details</Button>
                    </Col>
                </Row>
            </form>
        </div>
    )
}
