import React, { useState } from 'react';
import { Header, Menu, RegistrationForm, Footer} from '../components';
import { Link, useHistory } from "react-router-dom";

export const Register = () => {

    return (
        <div>
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8 main-content">
                    <div className="container">
                        <Header/>
                        {/* <div className="header-login">
                            <Link to='/login'>Log In</Link>
                        </div>
                        <hr className="header-hr"></hr>
                        <Menu /> */}
                        <hr></hr>
                        <p className="page-title">
                            Registration
                        </p>
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <RegistrationForm />
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
    )
}
