import React from 'react';
import { Header, Menu, ChooseMembershipComp, Footer} from '../components';
import { Link, useHistory } from "react-router-dom";

export const ChooseMembership = () => {
    let history = useHistory();
    let res = {};

    return (
        <div>
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8 main-content">
                    <div className="container">
                        <Header/>
                        <div className="header-login" >
                            <Link to='/login'>Log In</Link>
                        </div>
                        {/* <hr className="header-hr"></hr>
                        <Menu /> */}
                        <hr></hr>
                        <p className="page-title">
                            Membership Application
                        </p>
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="text-center">Select Membership Type</h3>
                                <ChooseMembershipComp />
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
    )
}
