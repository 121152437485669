import React from 'react';
import { Link } from "react-router-dom";

export const Header = () => {
    
    return (
        <div>
            <div className="header-menu">
                <div className="row">
                    <div className="col-md-8">
                        <div className="header-logo">
                            <Link className="link" to="/">
                                <img src="../../images/logo.png" alt="fbn-logo" className="img-fluid img-fluid1" />
                            </Link>
                        </div>
                    </div>
                    {/* <div className="col-md-4">
                        <span className="search-span">
                            <input 
                                type="email" 
                                className="form-control search-field" 
                                placeholder="Search..."
                            />
                            <button className="btn btn-search btn-sm search-btn" type="button">
                                Search
                            </button>
                        </span>
                    </div> */}
                </div>
            </div>
        </div>
    )
}
