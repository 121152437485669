import React, { useState, useEffect } from 'react';
import { Header, Menu, AddReferees, Footer} from '../components';
import { Link, useHistory } from "react-router-dom";

export const Register4 = () => {
    let history = useHistory();
    let res = {};

    const [user_data, setUser] = useState([]);

    useEffect(async () => {
        let user_data1 = JSON.parse(localStorage.getItem("user_data"));
        // (JSON.parse(localStorage.getItem('user_data')) ?
        //             JSON.parse(localStorage.getItem('user_data')).firstName 
        // : '')
        if(user_data1 == null || user_data1 == ""){
            history.push('/');
        }else{
            setUser(user_data1)
        }
    }, []);

    return (
        <div>
            <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10 main-content">
                    <div className="container">
                        <Header/>
                        {/* <div className="header-login">
                            <Link to='/login'>Log In</Link>
                        </div>
                        <hr className="header-hr"></hr>
                        <Menu /> */}
                        <hr></hr>
                        <p className="page-title">
                            Add Referees
                        </p>
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-10">
                                <AddReferees user_data={user_data} />
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <div className="col-md-1"></div>
            </div>
        </div>
    )
}
