import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { GetUserDetails } from "../redux/actions";
import { Header, Menu, UploadProfilePicture, Footer} from '../components';
import { Link, useHistory } from "react-router-dom";
import { Button, ListGroupItem } from 'react-bootstrap';

export const Register2 = () => {
    let history = useHistory();

    const [user_data, setUser] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const getDetails = async () => {
            document.getElementById("loader").style.display = "block";
            const res = await dispatch(GetUserDetails(localStorage.getItem("username")));
            document.getElementById("loader").style.display = "none";
            let user_data1 = res.data
            if(user_data1 === null || user_data1 === ""){
                history.push('/');
            }else{
                setUser(user_data1)
                document.getElementById("continue-upload").disabled = true;
            }
        }
        getDetails();
    }, []);

    function trigerProfileUpload(profile_picture){
        document.getElementById("profile_picture").click()
    }

    const submitProfilePicture = async () => {
        document.getElementById("submit-profile-picture").click()
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8 main-content">
                    <div className="container">
                        <Header/>
                        {/* <div className="header-login">
                            <Link to='/login'>Log In</Link>
                        </div>
                        <hr className="header-hr"></hr>
                        <Menu /> */}
                        <hr></hr>
                        <p className="page-title">
                            Registration
                        </p>
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                {/* Profile Image */}
                                <div className="card card-primary card-outline">
                                    
                                    <div className="card-body box-profile">
                                        <div className="text-center">
                                            <UploadProfilePicture page={"complete_registration"} />
                                        </div>
                                        <h3 className="profile-username text-center">{user_data.username}</h3>
                                        <p className="text-muted text-center">{user_data.first_name} {user_data.last_name}</p>
                                        <p className="text-center">{user_data.email}</p>
                                    </div>
                                    {/* /.card-body */}
                                    <ListGroupItem className="px-4">
                                        <div style={{textAlign: "center"}} className="row">
                                            <div className="col-md-1"></div>
                                            <div className="col-md-5">
                                                <Button block outline 
                                                    color="success"
                                                    onClick={() => trigerProfileUpload("profile_picture")}
                                                    className="dish-btn-secondary"
                                                    id="upload-photo"
                                                >Browse</Button>
                                            </div>
                                            <div className="col-md-5">
                                                <Button block outline 
                                                    color="success"
                                                    onClick={() => submitProfilePicture()}
                                                    className="dish-btn-secondary"
                                                    id="continue-upload"
                                                >Upload Profile Photo</Button>
                                            </div>
                                            <div className="col-md-1"></div>
                                        </div>
                                    </ListGroupItem>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
    )
}
