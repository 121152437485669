import React, { useState } from 'react';
import { saveDocument } from "../redux/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Success_Message, Error_Message, Toast_Message } from "../shared";
import { Row, Col } from 'react-bootstrap';
import ReactInputDateMask from 'react-input-date-mask';

export const UploadDocuments = (props) => {
    let res = {};
    const dispatch = useDispatch();
    let history = useHistory();
    // /////////////////////////////////////////////////////////////////////////////////////////
    const [inputList, setInputList] = useState([{ document_name: "", document_date: "", customFile: "" }]);

    // handle input change
    const handleInputChange = (e, index) => {
        index = index - 2;
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };
  
    // handle click event of the Remove button
    const handleRemoveClick = index => {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
    };
  
    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { document_name: "", document_date: "", customFile: "" }]);
    };

    // /////////////////////////////////////////////////////////////////////////////////////////
    async function handleSubmit() {
        let i = 1;
        let numOfRow = document.getElementsByName("document_name"); 
        for (i = 1; i < numOfRow.length; i++) {
            let document_name ='';
            if(document.getElementById("document_name-"+i).value === 'Others') {
                document_name = document.getElementById("document_name1-"+i).value;
            }else {
                document_name = document.getElementById("document_name-"+i).value;
            }

            let max_file_size = 0.5 * 1024 * 1024;
            let customFile = document.getElementById("customFile-"+i);

            let document_date = document.getElementsByClassName("document_date-"+i)[0].value;
            let dd = document_date.split('/')[0];
            let mm = document_date.split('/')[1];
            let yy = document_date.split('/')[2];
            let document_date1 = dd+"-"+mm+"-"+yy;
            let regExp = /[a-zA-Z]/g;
                        
            if(customFile.files[0] === undefined) {
                return Toast_Message("warning", "Please select document");
            }else if(customFile.files[0].size > max_file_size) {
                return Toast_Message("warning", "Please check documents. Document larger than 500kb");
            }else if(regExp.test(document_date1)){
                return Toast_Message("warning", "Please enter document(s) date correctly");
            }else if(document_name === "" || document_name === "Select") {
                return Toast_Message("warning", "Please select document(s) name");
            }else {
            }
        }
        saveDocuments();
    }

    async function saveDocuments() {  
        let i = 0;
        let numOfRow = document.getElementsByName("document_name"); 
        // check if all fields are filled
        for (i = 1; i < numOfRow.length; i++) {
            let document_name ='';
            if(document.getElementById("document_name-"+i).value === 'Others') {
                document_name   = document.getElementById("document_name1-"+i).value;
            }else {
                document_name   = document.getElementById("document_name-"+i).value;
            }
            
            let document_date   = document.getElementsByClassName("document_date-"+i)[0].value;
            let customFile      = document.getElementById("customFile-"+i);

            let dd = document_date.split('/')[0];
            let mm = document_date.split('/')[1];
            let yy = document_date.split('/')[2];
            let document_date1 = dd+"-"+mm+"-"+yy;

            const formData = new FormData();
            formData.append('document', customFile.files[0]);
            document.getElementById("loader").style.display = "block";
            res = await dispatch(saveDocument(localStorage.getItem("username"), document_name, document_date1, formData));
            document.getElementById("loader").style.display = "none";
            if(res.status === "success"){ 
                if(numOfRow.length === (i+1)) {
                    await Success_Message(res.data);
                    setTimeout(function(){ 
                        history.push('/add-referees') 
                    }, 2000);
                }
            }else{
                Error_Message(res.data);
            }
        }
    }

    return (
        <div>  
            {/* ///////////////////////////////////////////////////////////////////////// */}
            <div className="row" id="add_table_content">
                <table id="myTable example1" className="table order-list table-bordered table-striped">
                    <thead>
                        <tr style={{textAlign: "center"}}>
                            <th>S/N</th>
                            <th>Document Name</th>
                            <th>Document Date</th>
                            <th>Document</th>
                            <th style={{paddingLeft: "30px", paddingRight: "30px"}}>Action</th>
                        </tr>
                    </thead>
                    <tbody id="add_table">
                        {/* <tr>
                            <td>1</td>
                            <td>
                                <select 
                                    className={'reg-input'}
                                    value=''
                                    name="document_name" 
                                    id={"document_name-0"}
                                    onChange={e => handleInputChange(e, 0)} 
                                >
                                    <option value='OLevel results'>OLevel results</option>
                                </select>
                            </td>
                            <td>
                                <ReactInputDateMask  
                                    mask='dd/mm/yyyy' 
                                    showMaskOnFocus={false}  
                                    className = {"document_date-0"}
                                    name="document_date"
                                    value="" 
                                    showMaskOnHover={true} 
                                    placeholder="enter date"
                                />
                            </td>
                            <td>
                                <input 
                                    type="file" 
                                    className="form-control"
                                    id={"customFile-0"}
                                    accept=".pdf"
                                    // onChange={e => { onChange(e) }}
                                />
                            </td>
                            <td></td>
                        </tr> */}
                        <tr>
                            <td>1</td>
                            <td>
                                <select 
                                    className={'reg-input'}
                                    value=''
                                    name="document_name" 
                                    id={"document_name-1"}
                                    onChange={e => handleInputChange(e, 1)} 
                                >
                                    <option value='BSc.-B Tech. Certificate'>BSc./B Tech. Certificate</option>
                                </select>
                            </td>
                            <td>
                                <ReactInputDateMask  
                                    mask='dd/mm/yyyy' 
                                    showMaskOnFocus={false}  
                                    className = {"document_date-1"}
                                    name="document_date"
                                    value="" 
                                    showMaskOnHover={true} 
                                    placeholder="enter date"
                                />
                            </td>
                            <td>
                                <input 
                                    type="file" 
                                    className="form-control"
                                    id={"customFile-1"}
                                    accept=".pdf"
                                    // onChange={e => { onChange(e) }}
                                />
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>
                                <select 
                                    className={'reg-input'}
                                    value=''
                                    name="document_name" 
                                    id={"document_name-2"}
                                    onChange={e => handleInputChange(e, 2)} 
                                >
                                    <option value='BM.Sc.-M.Tech. Certificate'>M.Sc./M.Tech. Certificate</option>
                                </select>
                            </td>
                            <td>
                                <ReactInputDateMask  
                                    mask='dd/mm/yyyy' 
                                    showMaskOnFocus={false}  
                                    className = {"document_date-2"}
                                    name="document_date"
                                    value="" 
                                    showMaskOnHover={true} 
                                    placeholder="enter date"
                                />
                            </td>
                            <td>
                                <input 
                                    type="file" 
                                    className="form-control"
                                    id={"customFile-2"}
                                    accept=".pdf"
                                    // onChange={e => { onChange(e) }}
                                />
                            </td>
                            <td></td>
                        </tr>
                        {inputList.map((x, i) => {
                            return (
                                // <div className={i}>
                                    <tr key={(i+2)}>
                                        <td>{(i+2) + 1}</td>
                                        <td>
                                            {/* <input
                                                type="text"
                                                // className="form-control"
                                                name="document_name"
                                                id={"document_name-"+i}
                                                value={x.document_name}
                                                onChange={e => handleInputChange(e, i)}
                                            /> */}

                                            <select 
                                                className={'reg-input'}
                                                value={x.document_name}
                                                name="document_name" 
                                                id={"document_name-"+(i+2)}
                                                onChange={e => handleInputChange(e, (i+2))} 
                                            >
                                                <option value='Select'>Select</option>
                                                {/* <option value='OLevel results'>OLevel results</option> */}
                                                <option value='BSc.-B Tech. Certificate'>BSc./B Tech. Certificate</option>
                                                <option value='BM.Sc.-M.Tech. Certificate'>M.Sc./M.Tech. Certificate</option>
                                                <option value='CV-Resume'>CV/Resume</option>
                                                <option value='Others'>Others(specify)</option>
                                            </select>
                                            {/* <span style={{display: 1 === 2}}> */}
                                            { x.document_name === 'Others' ?
                                                <input 
                                                    type="text" 
                                                    name="document_name1" 
                                                    id={"document_name1-"+(i+2)}
                                                    placeholder="Enter value..." 
                                                    value={x.document_name1}
                                                    onChange={e => handleInputChange(e, (i+2))}
                                                    className={'reg-input'} 
                                                /> 
                                            : '' }
                                            {/* </span> */}
                                        </td>
                                        <td>
                                            <ReactInputDateMask  
                                                mask='dd/mm/yyyy' 
                                                showMaskOnFocus={false}  
                                                // className="form-control"
                                                className = {"document_date-"+(i+2)}
                                                name="document_date"
                                                value="" 
                                                // onChange={ handleDateChange } 
                                                showMaskOnHover={true} 
                                                placeholder="enter date"
                                                // id={"document_date-"+i}
                                            />
                                        </td>
                                        <td>
                                            <input 
                                                type="file" 
                                                className="form-control"
                                                id={"customFile-"+(i+2)}
                                                accept=".pdf"
                                                // onChange={e => { onChange(e) }}
                                            />
                                        </td>
                                        <td>
                                            {/* <div className="btn-box"> */}
                                            {inputList.length !== 1 && <button
                                                className="btn btn-md btn-danger"
                                                onClick={() => handleRemoveClick(i)}><i className="fas fa-trash-alt"></i></button>}
                                            {inputList.length - 1 === i && <button style={{marginLeft: "15px"}} className="btn btn-md btn-success" onClick={handleAddClick}><i className="fas fa-plus-circle"></i></button>}
                                            {/* </div> */}
                                        </td>
                                    </tr>
                                // </div>
                            );
                        })}
                    </tbody>
                    {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
                </table>
                <small style={{color: "firebrick"}}>Supported file type: PDF. Maximum file size: 500kb </small>
            </div>
            <Row style={{float: "right"}}>
                <Col xs={12}>
                    <button 
                        type="button" 
                        className="btn btn-primary" 
                        onClick={() => handleSubmit()}
                    ><i className="fas fa-save"></i> Save Document(s)</button>
                </Col>
            </Row>
            {/* ///////////////////////////////////////////////////////////////////////// */}
        </div>
    )
}
