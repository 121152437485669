import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";

export const Logout = () => {
    let history = useHistory();
    
    useEffect(() => {
        // setTimeout(function(){ 
            localStorage.setItem("isAuth", false);
            localStorage.setItem("user_data", "")
            window.location.href = '/'; 
        // }, 2000);
    }, []);

    return (
        <div>
            
        </div>
    )
}
