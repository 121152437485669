import React from 'react';
import { Link } from 'react-router-dom';
import pageNotFoundImage from '../assets/pageNotFound.svg';
import rectLine from '../assets/rectLine.svg';

const PageNotFound = () => (
    <div className='row' 
        style={{ justifyContent: 'space-around', backgroundColor: '#f2f9fe', height: '100vh', padding: '122px 10px' }}
    >
        <div className='col-md-6' style={{ width: '443px', height: '197px' }}>
            <img src={pageNotFoundImage} alt='page not found' />
        </div>
        <div className='col-md-6'>
            <p style={{
                fontSize: '3.5rem',
                color: '#023a7d',
                fontWeight: 'bold'
            }}>OOPS!...</p>
            <img src={rectLine} alt="horizontal line" />
            <p style={{
                fontSize: '30px',
                color: '#023a7d',
                textDecoration: 'none solid rgb(2, 58, 125)',
                marginTop: '47px'
            }}>Page Not Found</p>
            <p style={{
                fontSize: '16px',
                color: '#023a7d',
                textDecoration: 'none solid rgba(2, 58, 125, 0.6)',
                lineHeight: '24px'
            }}>The page you are looking for might have been removed, <br />had its name changed, or is temporarily unavilable</p>
            <Link to='/' style={{ textDecoration: 'none' }}>
                <button style={{
                    width: '120px',
                    height: '40px',
                    borderRadius: '4px',
                    backgroundColor: '#012e64',
                    fontSize: '12px',
                    color: '#ffffff'
                }} className="button">BACK TO HOME</button>
            </Link>
        </div>
    </div>
);

export default PageNotFound;