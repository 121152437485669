export const ApiEndpoints = {
    SIGN_UP: 'sign_up',
    UPDATE_PROFILE_PICTURE:  'update/profile_picture',
    UPDATE_PROFILE: 'update/profile',
    GET_COUNTRIES: 'get/countries',
    CHECK_ACTIVATION: 'check/activation',
    SIGN_IN: 'sign_in',
    UPLOAD_DOCUMENTS: 'upload_documents',
    SAVE_REF: 'save/referee',
    PAY_APPLICATION_FEE: 'pay/application_fee',
    GET_USER_DETAILS: 'get/user_details',
    UPDATE_MEMBERSHIP: 'update/membership',
    VERIFY_APPLICATION_PAYMENT: 'verify/application_fee',
}

export const AppEndpoints = {
    
}

