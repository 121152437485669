import { ApiEndpoints, INSERT_AUTH, INSERT_UPDATE_OR_DELETE_REQUEST, SERVER_REQUEST } from "../../shared";
import { SIGN_UP, SIGN_IN, FORGET_PASSWORD, CHECK_PASSWORD, RESET_PASSWORD, UPDATE_PROFILE, PAY_APPLICATION_FEE, UPDATE_PROFILE_PICTURE, GET_USER_DETAILS, UPDATE_MEMBERSHIP, VERIFY_APPLICATION_PAYMENT } from "../ActionTypes";

export const UserSignup = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.SIGN_UP, 'post',  { user: user_data }, 'json' );
    if (response.data.success === true) {
        return {status: "success", data: response.data.data};
    } else if(response.data.success === 'validationError'){
        const { email, password, username } = response.data.data;
        if(email){
            return {status: "error", data: email}
            // email.map(emailErr=>{ 
            //     alert("j")
            //     alert(emailErr)
            //     return {status: "error", data: emailErr};
            // })
        }else if(username){
            return {status: "error", data: username};
        }
        else if(password){
            return {status: "error", data: password};
        }else{
            return {status: "error", data: "Please fill form correctly"};
        }
    }else {
        return {status: "error", data: "An error occured"};
    }
}

export const updateProfilePicture = (username, profile_picture) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(`${ApiEndpoints.UPDATE_PROFILE_PICTURE}/${username}`, 'post',  profile_picture, 'file' );

    // await dispatch({
    //     type: UPDATE_PROFILE_PICTURE,
    //     payload: response.data,
    // });
    

    
    if (response.data.success) {
        return { status: "success", data: "Profile upload sccessfully" };
    } else {
        const { profile_picture } = response.data.data;
        if(profile_picture) {
            return { status: "error", data: profile_picture };
        }else {
            return { status: "error", data: "An error occured" };
        }
    }
}

export const UpdateProfile = (user_data) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.UPDATE_PROFILE, 'post',  user_data, 'json' );

    await dispatch({
        type: UPDATE_PROFILE,
        payload: response.data,
    });
    
    if (response.status === 201) {
        return { status: "success", data: response.data };
    } else {
        return { status: "error", data: "An error occured" };
    }
}

export const UserSignin = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.SIGN_IN, 'post', { user: user_data }, 'json');
    
    if(response){
        if (response.data.success) {
            // Success_Message("Login successful")
            await dispatch({
                type: SIGN_IN,
                payload: response.data.data,
            });
            
            const {
                User: { auth },
            } = getState();
            
            return {status: "success", data: auth};
        } else {
            // Error_Message("invalid username or password")
            return {status: "error", data: response.data.data};
        }
    }else{
        return {status: "error", data: "invalid username or password"};
    }
}

export const UserForgetPassword = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.FORGET_PASSWORD, 'post', { user: user_data }, 'json');
    
    if(response){
        if (response.status === 200) {
            // alert("Check Road 1")
            await dispatch({
                type: FORGET_PASSWORD,
                payload: response.data.data,
            });
            
            const {
                User: { auth },
            } = getState();
            
            return {status: "success", data: auth};
        } else {
            // alert("Check Road 2")
            // Error_Message("invalid username or password")
            return {status: "error", data: response.data.data};
        }
    }else{
        return {status: "error", data: "invalid username or password"};
    }
}

export const CheckPasswordCode = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.CHECK_PASSWORD, 'post', { user: user_data }, 'json');
    
    if(response){
        if (response.status) {
            await dispatch({
                type: CHECK_PASSWORD,
                payload: response.data,
            });
            
            return {status: "success", data: response.data};
        } else {
            // Error_Message("invalid username or password")
            return {status: "error", data: "invalid code"};
        }
    }else{
        return {status: "error", data: "invalid code"};
    }
}

export const UserResetPassword = (user_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.RESET_PASSWORD, 'post', { user: user_data }, 'json');
    
    if(response){
        if (response.status) {
            await dispatch({
                type: RESET_PASSWORD,
                payload: response.data.data,
            });
            
            return {status: "success", data: response.data.data};
        } else {
            // Error_Message("invalid username or password")
            return {status: "error", data: "Password error"};
        }
    }else{
        return {status: "error", data: "Password error"};
    }
}

export const CheckActivation = (code, username) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.CHECK_ACTIVATION, 'post', {code: code, username: username}, 'json');
    
    if(response){
        if (response.status) {
            
            return {status: "success", data: response.data};
        } else {
            // Error_Message("invalid username or password")
            return {status: "error", data: "invalid code"};
        }
    }else{
        return {status: "error", data: "invalid code"};
    }
}

export const saveReferees =  (ref_data) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.SAVE_REF, 'post', ref_data, 'json');

    if (response.data.success === true) {
        return {status: "success", data: "Referees added successfully"};
    }else {
        return {status: "error", data: "An error occured"};
    }
}

export const saveDocument = (username, document_name, document_date, document) => async (dispatch, getState) => {
    const response = await INSERT_AUTH(ApiEndpoints.UPLOAD_DOCUMENTS+"/"+username+"/"+document_name+"/"+document_date, 'post', document, 'json');

    if (response.data.success === true) {
        return {status: "success", data: response.data.data};
    } else if(response.data.success === 'validationError'){
        const { document } = response.data.data;
        if(document){
            return {status: "error", data: document}
        }else{
            return {status: "error", data: "Upload failed"};
        }
    }else {
        return {status: "error", data: "An error occured"};
    }
}

export const PayApplicationFee =(payment_data) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.PAY_APPLICATION_FEE, 'post', payment_data, 'json' );
    
    await dispatch({
        type: PAY_APPLICATION_FEE,
        payload: response.data,
    });
    
    if (response.data.success) {
        return { status: "success", data: response.data.data };
    } else {
        return { status: "error", data: response.data.data };
    }
}

export const GetUserDetails = (username) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(`${ApiEndpoints.GET_USER_DETAILS}/${username}`, 'get', "", 'json' );
    
    await dispatch({
        type: GET_USER_DETAILS,
        payload: response.data.data,
    });
    
    if (response.data.success) {
        return { status: "success", data: response.data.data };
    } else {
        if(response.data.data == "No user found") {
            return { status: "error", data: null };
        }
        return { status: "error", data: "An error occured" };
    }
}

export const UpdateMembership =(username, membership) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.UPDATE_MEMBERSHIP, 'post', { username: username, membership: membership }, 'json' );
    
    if (response.data.success) {
        return { status: "success", data: response.data.data };
    } else {
        return { status: "error", data: response.data.data };
    }
}

export const verifyApplicationPayment = (payment_data) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.VERIFY_APPLICATION_PAYMENT, 'post', payment_data, 'json' );

    await dispatch({
        type: VERIFY_APPLICATION_PAYMENT,
        payload: response.data,
    });
    
    if (response.data.success) {
        return { status: "success", data: response.data.data };
    } else {
        return { status: "error", data: "Something went wrong with your payment. Pleasse contact support." };
    }
}
