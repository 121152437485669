import React from 'react';
import { Header, Menu, SigninForm, Footer} from '../components';
import { Link, useHistory } from "react-router-dom";

export const Signin = () => {
    
    return (
        <div>
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8 main-content">
                    <div className="container">
                        <Header/>
                        <div className="header-login"> 
                            <Link to='/'> Register</Link>
                        </div>
                        <hr className="header-hr"></hr>
                        {/* <Menu />
                        <hr></hr> */}
                        <p className="page-title">
                            Sign in
                        </p>
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <SigninForm />
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
    )
}
