import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { PayApplicationFee, GetUserDetails, verifyApplicationPayment } from "../redux/actions";
import { Header, Menu, UploadProfilePicture, PersonalDetails, Footer} from '../components';
import { Link, useHistory } from "react-router-dom";
import { Button, ListGroupItem } from 'react-bootstrap';
import { Success_Message, Warning_Message, Error_Message } from "../shared";

export const Profile = () => {
    let history = useHistory();
    let res = {};
    if(localStorage.getItem("user_data") == null || localStorage.getItem("user_data") === ""){
        window.location.href = '/login';
    }
    
    const [membershipType, setMembershipType] = useState("");
    const [currency, setCurrency] = useState("");
    const [handlingFee, setHandlingFee] = useState(0);
    const [applicationFee, setApplicationFee] = useState(0);

    const username = JSON.parse(localStorage.getItem("user_data")).username;
    const [status, setStatus]   = useState("");
    const [status1, setStatus1] = useState("");
    const dispatch = useDispatch();

    const [user_data1, setUser] = useState([]);
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        confirmPaymentStatus();
        getUserDetails();
    }, []);

    const getUserDetails = async () => {
        document.getElementById("loader").style.display = "block";
        res = await dispatch(GetUserDetails(username));
        document.getElementById("loader").style.display = "none";
        
        if(res.data == null || res.data === ""){
            history.push('/login');
        }else{
            setLoggedIn(true)
            setUser(res.data)
            if(res.data.status === 0) {
                setStatus ("Email Not Verified")
                setStatus1("Verify email through the link sent to you")
            }else if(res.data.status === "1") {
                setStatus ("Pending")
                setStatus1("Awaiting admin approval")
            }else if(res.data.status == "2") {
                setStatus("Approved.")
                setStatus1("Please make payment")
            }else if(res.data.status == "3") {
                setStatus("Active")
            }

            getAmount(res.data.membership_type);
            loadSignature(res.data.signature)
        }
    }

    const loadSignature = (signature) => {
        let signatureImage = document.createElement("img");
        signatureImage.src = signature;
        // signatureImage.width = "150";
        signatureImage.height = "55";
        document.querySelector("#imageContainer").innerHTML = signatureImage.outerHTML;
    }

    const getAmount = async (membership_type) => {
        if(membership_type === 1) {
            setApplicationFee(50000); setCurrency("NGN"); setMembershipType(membership_type)
        }else if(membership_type === 2) {
            setApplicationFee(30000); setCurrency("NGN"); setMembershipType(membership_type)
        }else if(membership_type === 3) {
            setApplicationFee(20000); setCurrency("NGN"); setMembershipType(membership_type)
        }else if(membership_type === 4) {
            setApplicationFee(10000); setCurrency("NGN"); setMembershipType(membership_type)
        }else if(membership_type === 5) {
            setApplicationFee(15000); setCurrency("NGN"); setMembershipType(membership_type)
        }else if(membership_type === 6) {
            setApplicationFee(100); setCurrency("USD"); setMembershipType(membership_type)
        }
    }

    const confirmPaymentStatus = async () => {
        try {
            // get the url param
            var url_string = window.location.href;
            var transaction_details = url_string.split('?')[1];
            var transaction_details = transaction_details.split('&');
            var transaction_status  = transaction_details[0];
            var transaction_ref     = transaction_details[1];

            if(transaction_status == "success"){
                const payment_data ={
                    transaction_id : transaction_ref, 
                    username : username,
                }
                document.getElementById("loader").style.display = "block";
                res = await dispatch(verifyApplicationPayment(payment_data));
                document.getElementById("loader").style.display = "none";

                if(res.status === "success"){ 
                    Success_Message("Payment successful.")
                    history.push('/profile');
                }else{
                    Error_Message("Transaction error")
                }

            }else if(transaction_status == "tampered"){
                Error_Message("Transaction error. Transaction has been tampered with")
            }else if(transaction_status == "Fraud"){
                Error_Message("Transaction error. Fraud transaction detected")
            }else if(transaction_status == "No-transaction"){
                Error_Message("No transaction was found")
            }else {
                Error_Message("an error occured")
            }
        } catch (error) {
  
        }
    }

    // /////////////////////////////////////////////////////////
    function trigerProfileUpload(profile_picture){
        document.getElementById("profile_picture").click()
    }
    // /////////////////////////////////////////////////////
    const payNow = async () => {  
        const payment_data = {
            currency: currency,
            amount : applicationFee + handlingFee,
            username : user_data1.username,
            handlingFee: handlingFee,
        }
        document.getElementById("loader").style.display = "block";
        res = await dispatch(PayApplicationFee(payment_data));
        document.getElementById("loader").style.display = "none";
        if(res.status === "success"){ 
            var win = window.open(res.data, '_self');
            // var win = window.open(json.data.data);
            win.focus();
        }else{
            Error_Message(res.data)
        }
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10 main-content">
                    <div className="container">
                        <Header/>
                        <div className="header-login" >
                            <Link to='/logout'>Log Out</Link>
                        </div>
                        <hr className="header-hr"></hr>
                        {/* <Menu /> */}
                        {/* <hr></hr> */}
                        <p className="page-title">
                            Profile
                        </p>
                        <div className="row">

                            <div className="col-md-3">
                                {/* Profile Image */}
                                <div className="card card-primary card-outline" style={{marginBottom: "15px"}}>
                                    <div className="card-body box-profile">
                                        <div className="text-center">
                                            { 
                                                loggedIn ?
                                                <UploadProfilePicture page={"profile"} /> :
                                                ""
                                            }
                                        </div>
                                        <h3 className="profile-username text-center">{user_data1.username}</h3>
                                        <p className="text-muted text-center">{user_data1.first_name} {user_data1.last_name}</p>
                                        <p className="text-center">{user_data1.email}</p>
                                    </div>
                                    {/* /.card-body */}
                                    <ListGroupItem className="px-4">
                                        <Button block outline 
                                                color="success"
                                                onClick={() => trigerProfileUpload("profile_picture")}
                                                className="dish-btn-secondary"
                                                id="upload-photo"
                                                style={{ width: "100%" }} 
                                            >Update Profile Photo</Button>
                                    </ListGroupItem>
                                </div>

                                <div className="card card-primary card-outline" style={{marginBottom: "15px"}}>
                                    <div style={{padding: "5px 10px", fontSize: "12px", textAlign: "center"}}>
                                        <p>Account Status: <strong style={{color: "#003F54"}}>{status}</strong></p>
                                        <p><strong style={{color: "#D11600"}}>{status1}</strong></p>
                                        <hr></hr>
                                    </div>
                                    { user_data1.status === 2 ?
                                        <>
                                            <table style={{margin: "0px 15px", fontSize: "14px"}}>
                                                <tbody>
                                                    <tr style={{marginBottom: "5px"}}>
                                                        <td>Application fee</td>
                                                        <td>{applicationFee} ({currency})</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Handling fee</td>
                                                        <td>{handlingFee} ({currency})</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total</td>
                                                        <td>{applicationFee+handlingFee} ({currency})</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <ListGroupItem className="px-4">
                                                <Button
                                                    data-toggle="modal" 
                                                    data-target="#pay-now" 
                                                    style={{cursor: "pointer", width: "100%"}} 
                                                    onClick={() => payNow()}
                                                >Pay now</Button>
                                            </ListGroupItem>
                                        </>
                                        : ""
                                    }
                                </div>

                                <div className="card card-primary card-outline">
                                    <div style={{padding: "5px 10px", fontSize: "12px", textAlign: "center"}}>
                                        <h6>Signature</h6>
                                        <hr/>
                                    </div>
                                    <div id="imageContainer"></div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="card">
                                    <div className="card-body">
                                        <PersonalDetails />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <div className="col-md-1"></div>
            </div>
        </div>
    )
}
