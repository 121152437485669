import React, { useState, useEffect } from 'react';
import { Header, Menu, UploadDocuments, Footer} from '../components';
import { Link, useHistory } from "react-router-dom";
import { UploadStudentDocuments } from '../components/UploadStudentDocuments';

export const Register5 = () => {
    let history = useHistory();
    let res = {};

    const [user_data, setUser] = useState([]);

    useEffect(()=> {
    }, []);

    return (
        <div>
            <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10 main-content">
                    <div className="container">
                        <Header/>
                        {/* <div className="header-login">
                            <Link to='/login'>Log In</Link>
                        </div>
                        <hr className="header-hr"></hr>
                        <Menu /> */}
                        <hr></hr>
                        <p className="page-title">
                            Upload Documents {localStorage.getItem("membership_type")}
                        </p>
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-10">
                                {localStorage.getItem("membership_type") == 4 ?
                                    <UploadStudentDocuments user_data={user_data} /> :
                                    <UploadDocuments user_data={user_data} />
                                }
                                
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <div className="col-md-1"></div>
            </div>
        </div>
    )
}
