import React from 'react';
import { Link } from 'react-router-dom';
import success from '../assets/successful.svg';
import rectLine from '../assets/rectLine.svg';

const RegComplete = () => (
    <div className='row' 
        style={{ justifyContent: 'space-around', backgroundColor: '#f2f9fe', height: '100vh', padding: '122px 10px' }}
    >
        <div className='col-md-6'  style={{textAlign: 'center'}}>
            <img src={success} alt='Successful' style={{height: '350px', width: 'auto'}}/>
        </div>
        <div className='col-md-6'>
            <p style={{
                fontSize: '3.5rem',
                color: '#023a7d',
                fontWeight: 'bold'
            }}>SUCCESSFUL!</p>
            <img src={rectLine} alt="horizontal line" />
            <p style={{
                fontSize: '30px',
                color: '#023a7d',
                textDecoration: 'none solid rgb(2, 58, 125)',
                marginTop: '47px'
            }}>Registration Complete</p>
            <p style={{
                fontSize: '16px',
                color: '#023a7d',
                textDecoration: 'none solid rgba(2, 58, 125, 0.6)',
                lineHeight: '24px'
            }}>
                Thank you for completing the registration process, 
                please check your email (inbox/spam) and activate your account.
                <br />You will also receive an email when your application has been reviewed.
            </p>
            <Link to='/' style={{ textDecoration: 'none' }}>
                <button style={{
                    width: '120px',
                    height: '40px',
                    borderRadius: '4px',
                    backgroundColor: '#012e64',
                    fontSize: '12px',
                    color: '#ffffff'
                }} className="button">BACK TO HOME</button>
            </Link>
        </div>
    </div>
);

export default RegComplete;