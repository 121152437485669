import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { updateProfilePicture, GetUserDetails } from "../redux/actions";
import { Success_Message, Error_Message } from "../shared";

export const UploadProfilePicture = (props) => {
    let res = {};

    const [picture, setProfilePicture] = useState("");

    const dispatch = useDispatch();
    const [profile_picture, setProfileImage] = useState("../../images/default-avatar.png");

    const User = useSelector((state) => state.User);
    const { user }  = User;

    useEffect(() => {
      const getDetails = async () => {
        document.getElementById("loader").style.display = "block";
        res = await dispatch(GetUserDetails(localStorage.getItem("username")));
        
        document.getElementById("loader").style.display = "none";

        if(res.status == "success") {
          if(res.data.profile_picture == "" || res.data.profile_picture == null) {
          }else {
            setProfileImage(res.data.profile_picture)
            // this is because after upload it reloads the page
            // if(props.page == "complete_registration") {
            //   await Success_Message("Uploaded successfully. Please complete registration.")
            //   window.location.href = '/complete-registration'; 
            // }
          }
        }
      }
      getDetails();
    }, []);

    const onChangeProfilePicture = async (e) => {
        await setProfilePicture(e)
        await setProfileImage(e.target.files[0])
        if(props.page === "complete_registration") {
          document.getElementById("continue-upload").disabled = false;
        }else {
          document.getElementById("submit-profile-picture").click()
        }
    }

    // display the uploaded image
    function imageHandlerProfilePicture (e) {
        const reader = new FileReader();
        reader.onload = () =>{
          if(reader.readyState === 2){
            setProfileImage( reader.result );
          }
        }
        reader.readAsDataURL(e.target.files[0])
        // document.getElementById("upload-photo").disabled = true;
    };

    const onSubmitProfilePicture = async () => {
        const formData = new FormData();
        formData.append('profile_picture', picture.target.files[0])
        document.getElementById("loader").style.display = "block";
        res = await dispatch(updateProfilePicture(localStorage.getItem("username"), formData))
        document.getElementById("loader").style.display = "none";
        if(res.status === "success"){ 
          await Success_Message("Successfully uploaded") 
          if(props.page === "complete_registration") {
              window.location.href = '/sign-obligation'; 
            }
      }else{
          Error_Message(res.data)
      }
    }

    return (
        <div>
            <div>
                <img 
                className="profile-user-img img-fluid img-circle" 
                src={profile_picture} 
                alt="User profile" 
                style={{width: "150px"}}
                />
                <input 
                    type="file" 
                    color="primary"
                    id="profile_picture"
                    style={{display: "none"}}
                    // onChange={this.onChangeProfilePicture}
                    onChange={e => { onChangeProfilePicture(e); imageHandlerProfilePicture(e) }}
                />
                <Button
                    onClick={() => onSubmitProfilePicture()}
                    id="submit-profile-picture"
                    style={{display: "none"}}
                >Continue</Button>
            </div>
        </div>
    )
}
