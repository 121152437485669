import React, { useState } from 'react';
import { saveReferees } from "../redux/actions";
import { useDispatch } from "react-redux";
import { Success_Message, Error_Message, Toast_Message } from "../shared";
import { Row, Col } from 'react-bootstrap';
import { useHistory } from "react-router-dom";

export const AddReferees = (props) => {
    let res = {};
    let history = useHistory();
    const dispatch = useDispatch();
    // /////////////////////////////////////////////////////////////////////////////////////////
    // const [inputList, setInputList] = useState([{ firstName: "", lastName: "" }]);
    const [inputList, setInputList] = useState([{ ref_name: "", ref_email: "", ref_phone: "" }]);

    // handle input change
    const handleInputChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
    };
  
    // handle click event of the Remove button
    const handleRemoveClick = index => {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
    };
  
    // handle click event of the Add button
    const handleAddClick = () => {
    //   setInputList([...inputList, { firstName: "", lastName: "" }]);
        setInputList([...inputList, { ref_name: "", ref_email: "", ref_phone: "" }]);
    };

    // /////////////////////////////////////////////////////////////////////////////////////////
    async function handleSubmit() {
        let i = 0;
        let numOfRow = document.getElementsByName("ref_name"); 
        // check if all fields are filled
        for (i = 0; i < numOfRow.length; i++) {
            // let j = i +1; 
            let ref_name   = document.getElementById("ref_name-"+i).value;
            let ref_email   = document.getElementById("ref_email-"+i).value;
            let ref_phone   = document.getElementById("ref_phone-"+i).value;

            if(ref_name === "" || ref_email === "" || ref_phone === "") {
               return Toast_Message("warning", "All fields are required");
            }else {
                let ref_data = {
                    username: localStorage.getItem("username"),
                    ref_name: ref_name,
                    ref_email: ref_email,
                    ref_phone: ref_phone
                }
                
                document.getElementById("loader").style.display = "block";
                res = await dispatch(saveReferees(ref_data));
                document.getElementById("loader").style.display = "none";
                if(res.status == "success"){ 
                    if(numOfRow.length == (i+1)) {
                        await Success_Message(res.data);
                        setTimeout(function(){ 
                            history.push('/upload-profile-picture') 
                        }, 2000);
                    }
                }else{
                    Error_Message(res.data);
                }
            }
        }
    }

    return (
        <div>  
            {/* ///////////////////////////////////////////////////////////////////////// */}
            <div className="row" >
                <table id="myTable example1" className="table order-list table-bordered table-striped">
                    <thead>
                        <tr style={{textAlign: "center"}}>
                            <th>S/N</th>
                            <th>Referee's Name</th>
                            <th>Referee's Email</th>
                            <th>Referee's Phone Number</th>
                            <th style={{paddingLeft: "30px", paddingRight: "30px"}}>Action</th>
                        </tr>
                    </thead>
                    <tbody id="add_table">
                        {inputList.map((x, i) => {
                            return (
                                // <div className={i}>
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td><input
                                            type="text"
                                            // className="form-control"
                                            name="ref_name"
                                            id={"ref_name-"+i}
                                            value={x.ref_name}
                                            onChange={e => handleInputChange(e, i)}
                                        /></td>
                                        <td>
                                            <input
                                                type="email"
                                                // className="form-control"
                                                name="ref_email"
                                                id={"ref_email-"+i}
                                                value={x.ref_email}
                                                onChange={e => handleInputChange(e, i)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="tel"
                                                // className="form-control"
                                                name="ref_phone"
                                                id={"ref_phone-"+i}
                                                value={x.ref_phone}
                                                onChange={e => handleInputChange(e, i)}
                                            />
                                        </td>
                                        <td>
                                            {/* <div className="btn-box"> */}
                                            {inputList.length !== 1 && <button
                                                className="btn btn-md btn-danger btn-sm"
                                                onClick={() => handleRemoveClick(i)}><i className="fas fa-trash-alt"></i></button>}
                                            {inputList.length - 1 === i && <button style={{marginLeft: "15px"}} className="btn btn-md btn-success btn-sm" onClick={handleAddClick}><i className="fas fa-plus-circle"></i></button>}
                                            {/* </div> */}
                                        </td>
                                    </tr>
                                // </div>
                            );
                        })}
                    </tbody>
                    {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
                </table>
            </div>
            <Row style={{float: "right"}}>
                <Col xs={12}>
                    <button 
                        type="button" 
                        className="btn btn-primary" 
                        onClick={() => handleSubmit()}
                    ><i className="fas fa-save"></i> Save Referee(s)</button>
                </Col>
            </Row>
            {/* ///////////////////////////////////////////////////////////////////////// */}  
        </div>
    )
}