import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { UpdateMembership } from "../redux/actions";
import { useHistory } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import { Success_Message, Warning_Message, Error_Message } from "../shared";

export const ChooseMembershipComp = () => {
    let res = {};
    let history = useHistory();
    const dispatch = useDispatch();

    // useEffect(async () => {
    //     var url_string  = window.location.href;
    //     let url_details = url_string.split('?')[1];
    //     let message     = url_details.split('--')[0];
    //     let username    = url_details.split('--')[1];

    //     if(message === "success"){ 
    //         localStorage.setItem("username", username);
    //         await Success_Message("Account activated successfully");
    //     }else if(message === "already-activated") {
    //         await Warning_Message("Account already activated. Please proceed");
    //     }else if(message === "failed") {
    //         await Error_Message("Activated failed");
    //     }
    // }, []);

    const selectMembership = async (membership, membershipType) => {
        localStorage.setItem("membership_type", membership);
        Success_Message(`${membershipType} selected`)
        history.push('/register')
        // localStorage.setItem("membership_type", membership);
 
        // let user_data = JSON.parse(localStorage.getItem("username"));
        // document.getElementById("loader").style.display = "block";
        // res = await dispatch(UpdateMembership(localStorage.getItem("username"), membership));
        // document.getElementById("loader").style.display = "none";
        // if(res.status == "success") {
        //     await Success_Message(`${membershipType} selected`)
        //     history.push('/upload-profile-picture')
        // }else {
        //     Error_Message("Please select again")
        // }
    }

    return (
        <div>
            <Row style={{marginTop: "5%", marginBottom: "auto"}}>
                <Col md={12} xs={12}>
                    <div className="container-fluid">
                        <Row className="margin-bottom-35">
                            <h5 style={{color: 'firebrick'}}>Not Available</h5>
                            <Col md={4} xs={12} style={{margin: "auto"}}>
                                <div 
                                    className="card" 
                                    style={{
                                        height: "200px", 
                                        backgroundColor: "#8d67ab", 
                                        opacity: '0.3',
                                        color: "#ffffff", 
                                        // cursor: "pointer"
                                    }} 
                                    // onClick={() => selectMembership("1", "Fellowship")}
                                >
                                    <div className="card-body">
                                        <h4>
                                            Fellowship<br></br> 
                                            (50,000 Naira)
                                        </h4>
                                    </div>
                                    
                                </div>
                            </Col>
                            <Col md={4} xs={12} style={{margin: "auto"}}>
                                <div 
                                    className="card" 
                                    style={{height: "200px", backgroundColor: "#1e3264", color: "#ffffff", cursor: "pointer"}} 
                                    onClick={() => selectMembership("2", "Full Membership")}
                                >
                                    <div className="card-body">
                                        <h4>
                                            Full Membership<br></br>
                                            (30,000 Naira)
                                        </h4>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} xs={12} style={{margin: "auto"}}>
                                <div className="card" style={{height: "200px", backgroundColor: "#dc148c", color: "#ffffff", cursor: "pointer"}} onClick={() => selectMembership("3", "Associate Membership")}>
                                    <div className="card-body">
                                        <h4>
                                            Associate Membership<br></br> 
                                            (20,000 Naira)
                                        </h4>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="margin-bottom-35">
                            <Col md={4} xs={12}>
                                <div className="card" style={{height: "200px", backgroundColor: "#f59b23", color: "#ffffff", cursor: "pointer"}} onClick={() => selectMembership("4", "Student Membership")}>
                                    <div className="card-body">
                                        <h4>
                                            Student Membership<br></br>
                                            (10,000 Naira)
                                        </h4>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} xs={12}>
                                <div className="card" style={{height: "200px", backgroundColor: "#27856a", color: "#ffffff", cursor: "pointer"}} onClick={() => selectMembership("5", "Affiliate Membership")}>
                                    <div className="card-body">
                                        <h4>
                                            Affiliate Membership<br></br>
                                            (15,000 Naira)
                                        </h4>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} xs={12}>
                                <div className="card" style={{height: "200px", backgroundColor: "#907255", color: "#ffffff", cursor: "pointer"}} onClick={() => selectMembership("6", "International Membership")}>
                                    <div className="card-body">
                                        <h4>
                                            International Membership<br></br>
                                            (100 USD)
                                        </h4>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col md={2} xs={12}></Col>
            </Row>
        </div>
    )
}
