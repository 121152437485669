import { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AnimatedSwitch } from 'react-router-transition';
import { PublicRoute } from "./components/PublicRoute";

import { Register, Register2, SignObligation, Signin, ChooseMembership, Register3, Register4, Register5, Profile, Logout } from "./pages";
import Loader from "react-loader-spinner";
import PageNotFound from './pages/PageNotFound';
import RegComplete from './pages/RegComplete';

function App() {
  const [showLoaderDiv, setShowLoaderDiv] = useState("none");

  return (
    <div>
      {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
      <div id="loader" style={{position: "fixed", height:"100%", width:"100%", display: showLoaderDiv, zIndex: "1500"}}>
          <div style={{position: "fixed", top:"40%", left:"46%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px", textAlign: "center" }}>
              <Loader
                  type="Bars"
                  color="#183287"
                  secondaryColor="#ff9700"
                  height={50}
                  width={50}
                  // visible={loaderVisible}
                  // timeout={3000} //3 secs
              />
              <img src="images/logo.png" alt="NAMP Logo" className="brand-image elevation-3" style={{maxHeight: "40px", width: "auto", marginBottom: "20px"}} />
              <h6 style={{color: "#183287"}}>Loading...</h6>
          </div>
      </div>
      {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
      <Router>
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          
          {/* <PublicRoute path="/" exact component={Home} /> */}
          <PublicRoute path="/" exact component={ChooseMembership} />
          <PublicRoute path="/register" exact component={Register} />
          {/* <PublicRoute path="/activate_account" exact component={ActivateAccount} /> */}
          
          <PublicRoute path="/login" exact component={Signin} />
          {/* <PublicRoute path="/facial-verification" exact component={Signin2} /> */}
          
          <PublicRoute path="/complete-registration" exact component={Register3} />
          
          <PublicRoute path="/upload-documents" exact component={Register5} />
          <PublicRoute path="/add-referees" exact component={Register4} />
          <PublicRoute path="/upload-profile-picture" exact component={Register2} />
          <PublicRoute path="/sign-obligation" exact component={SignObligation} />
          <PublicRoute path="/registration-complete" exact component={RegComplete} />
          <PublicRoute path="/profile" exact component={Profile} />
          {/* <PublicRoute path="/our-executives" exact component={OurExecutives} /> */}
          {/* <PublicRoute path="/president-welcome-address" exact component={PresidentSpeech} /> */}
          {/* <PublicRoute path="/become-a-member" exact component={BecomeAMember} /> */}
          <PublicRoute path="/logout" exact component={Logout} />
          <Route component={PageNotFound} />
        </AnimatedSwitch>
      </Router>
    </div>
    
  );
}

export default App;
